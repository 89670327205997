@import "@backoffice/scss/index.scss";

.grid-wrapper {
	@include gridTableWrapper();
	.gridTable {
		@include gridTable();
	}
	margin-bottom: 4rem;
}

.e-bigger {
	margin-top: 20px;
	margin-bottom: 30px;
	.gridTable {
		@include gridTable();
		border-radius: 10px;
	}
	.export-excel-wrapper {
		@include flexbox(100%, row, flex-start, center);
		margin-bottom: 25px;
		.export-excel-button {
			width: 100%;
		}
	}
	.e-gridcontent {
		border-radius: 10px;
		.e-content {
			border-radius: 10px;
		}
	}
}
.e-bigger .e-grid.e-row-responsive .e-gridcontent td::before,
.e-bigger .e-grid.e-row-responsive .e-summarycontent td::before,
.e-bigger.e-grid.e-row-responsive .e-gridcontent td::before,
.e-bigger.e-grid.e-row-responsive .e-summarycontent td::before {
	font-weight: bolder !important;
}
