@import "@backoffice/scss/index.scss";
.news {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 35px;
        // border-bottom: 1px solid $color-primary;
    }
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }
    .btn-grid-end {
        @include btn-grid-end();
    }
}
