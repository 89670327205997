@import "@backoffice/scss/index.scss";
.bookings {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 25px;
    }
    
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }
    .filters-wrapper {
        @include flexbox(100%, row, flex-start, center);
        .date-wrapper {
            @include date-wrapper();
        }
        .booking-search {
            padding-top: 22px;
        }
    }
    .btn-grid-end {
        @include btn-grid-end();
    }

}

.e-custom-datepicker .e-calendar .e-content td.e-selected span.e-day, 
.e-custom-datepicker .e-calendar .e-content td.e-today.e-selected span.e-day { 
  background: $color-secondary !important; 
  color: white !important; 
} 
.e-custom-datepicker .e-calendar .e-content td.e-today span.e-day { 
  color: $color-secondary !important; 
  border: none !important; 
  box-shadow: none !important; 
} 
/* To change the Today button and date icon color*/ 
 
.e-custom-datepicker.e-date-wrapper 
  span.e-input-group-icon.e-date-icon.e-icons.e-active, 
.e-custom-datepicker .e-btn.e-flat.e-primary, 
.e-custom-datepicker .e-css.e-btn.e-flat.e-primary { 
  color: $color-secondary !important; 
} 
.e-custom-datepicker .e-btn.e-flat.e-primary::hover { 
  background: rgb(19 112 219 / 17%); 
  color: $color-secondary !important; 
} 