@import "@backoffice/scss/index.scss";

.tab-documentation {
  padding: 24px;
  width: 100%;

  @media screen and (max-width: $media-medium) {
    padding: 24px 0px;
  }

  .title {
    @include title(18px, $color-text, left, 0px, normal, bolder);
    margin-bottom: 5px;
    margin-top: 15px;
  }

  .row {
    @include flexbox(100%, row, flex-start, flex-start, wrap);
  }

  .title-uploader {
    @include title(18px, $color-text, left, 0px, normal, bolder);
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .subtitle-uploader {
    @include title(10px, $color-gray-clear, left, 0px, normal, bolder);
  }

  .uploader-wrapper {
    width: 50%;
    margin-bottom: 10px;
  }

  .contract-title,
  .certification-title {
    @include title(14px, $color-text, left, 0px, normal, bolder);
    margin-top: 10px;
  }

  .container {
    width: 50%;
    padding: 10px;
    margin: 0;
    @media screen and (max-width: $media-medium) {
      width: 100%;
    }
  }

  .container-document-azure {
    display: flex;
    justify-content: space-between;
  }

  .icon {
    cursor: pointer;
    &:hover {
      color: $color-primary-hover;
    }
  }

  .container-document-azure,
  .container-document-dynamic {
    min-width: 300px;
    width: 100%;
    margin: 10px 5px 10px 10px;
    .download-link {
      text-decoration: none;
      cursor: pointer;
      color: $color-text;
      &:hover {
        color: $color-secondary;
      }
    }
  }
  .btn-grid-end {
    @include btn-grid-end();
  }
  .btn-end {
    @include btn-end();
    margin-top: 30px;
  }
}
