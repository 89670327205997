@import "../../../scss/index.scss";

.textarea-wrapper {
	@include flexbox(100%, column, flex-start, flex-start);
	.textarea-label {
		color: $color-text;
		font-weight: bolder;
		margin: 0.5rem 0;
		display: inline-block;
		font-family: $font;
		font-size: 12px;
	}
	.textarea-control {
		width: 100%;
		font-size: 12px;
		border-color: $color-primary-variant2;
		border-radius: 14px;
		padding: 0.5rem;
		resize: none;
		box-sizing: border-box;
		margin-bottom: 1rem;
		line-height: 1.3;
		&:focus {
			outline: none;
			border: 2px solid $color-secondary;
		}
	}

	//disabled
	&.isDisabled {
		opacity: 1;
		.textarea-control {
			background-color: rgb(242, 242, 242);
		}
	}
}
