@import "../../../../scss/index.scss";

.forgot-password {
	width: 100%;
	max-width: 400px;
	padding: 0px 40px;
	
	.forgot-password-title {
		font-weight: bolder;
		margin-bottom: 15px;
        font-size: 14px;
	}

	.link-forgotPass {
        text-align: center;
        margin-bottom: 2rem;
        text-decoration: underline;
        font-weight: bolder;
        cursor: pointer;
        font-size: 12px;

        &:hover {
            color: $color-primary-hover;
        }

        &:active {
            color: $color-secondary;
        }
    }

	.pointer {
		cursor: pointer;
	}

	.button-container {
		button {
			width: 100%;
			margin-bottom: 0.5em;
		}
	}
}
