@import "@backoffice/scss/index.scss";
.past-bookings {
    width: 100%;
    .title {
        @include title(14px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }
    .subtitle {
        @include title(14px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }
}
