@import "@backoffice/scss/index.scss";
.layout {
  height: 100%;
  background-color: $color-main-bg;
  padding-top: 60px;

  .MuiToolbar-gutters {
    padding: 0;
  }

  .main {
    @include flexbox(100%, row, center, center);
    align-items: flex-start;
    background-color: $color-main-bg;
    height: calc(100vh - 60px);

    .main-content {
      @include flexbox(auto, column, flex-start, center);
      width: calc(100% - 250px);
      min-height: 90vh;
      @media screen and (max-width: $media-medium) {
        width: 100%;
      }
    }
  }
}
