@import "@backoffice/scss/index.scss";
.new-detail {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .return {
        @include return();
    }
    .new-detail-form {
        .row {
            flex-wrap: wrap;
        }
        .date-wrapper {
            @include date-wrapper();
        }
        .rich-text {
            @include flexbox(100%, row, flex-start, center);
            margin-bottom: 10px;
        }
        .title {
            @include title(18px, $color-text, left, 0px, normal, bolder);
            margin-bottom: 30px;
            padding-left: 20px;
        }
        .subtitle {
            @include title(12px, $color-text, left, 0px, normal, bolder);
            margin-bottom: 5px;
            padding-left: 20px;
        }
        .description {
            @include title(12px, $color-text, left, 0px, normal, normal);
            margin-bottom: 20px;
            padding-left: 20px;
        }
        .scheduleNew-check,
        .fixedNew-check,
        .hideNew-check {
            padding: 5px 20px 5px 20px;
            @include flexbox(100%, column, flex-start, flex-start);
            max-width: 200px;
            min-width: 100px;
            @media screen and (max-width: $media-medium-land) {
                @include flexbox(100%, column, flex-start, flex-start);
            }
        }
        .filePreview-wrapper {
            @include flexbox(auto, row, flex-start, center);
            padding-left: 10px;
            margin-bottom: 20px;

            .img-wrapper {
                @include img-wrapper();
                margin-bottom: 10px;
                img {
                    cursor: pointer;
                }
                &.empty {
                    .file-preview {
                        img {
                            object-fit: cover !important;
                            cursor: inherit;
                            &:hover {
                                box-shadow: none !important;
                                transform: none !important;
                            }
                        }
                    }
                }
            }
            .file-name {
                overflow-wrap: break-word;
                word-wrap: break-word;
                max-height: 20px;
                margin-right: 20px;
            }
            .inputfile {
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                position: absolute;
                z-index: -1;
            }
            .button-upload {
                @include flexbox(auto, row, flex-start, center);
                text-align: center;
                //border: 1px $color-green-clear solid;
                color: $color-green-dark;
                border-radius: 5px;
                padding: 0;
                margin: 0;
                cursor: pointer;
                width: auto;
                transition: 0.2s ease all;
                &:hover {
                    color: $color-green-clear;
                    //background-color: $color-green-clear;
                }
                label {
                    @include flexbox(auto, row, center, center);
                    text-align: center;
                }
            }
        }
    }
    .btn-end {
        @include btn-end();
    }
}
