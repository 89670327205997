@import "@backoffice/scss/index.scss";

.contacts-tenant-detail {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .return {
        @include return();
        margin-bottom: 25px;
    }
    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 25px;
    }
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .contact-detail-wrapper {
        @include flexbox(100%, row, flex-start, center, wrap);
        .col-md-3s {
            padding: 0;
            margin: 0;
        }
        .contact-detail-card {
            @media screen and (max-width: $media-small) {
                padding: 2px;
                margin: 20px 0px;
            }
            padding: 2px;
            margin: 20px 60px 20px 0px;
            cursor: pointer;
            background-color: #f3f3f3;
            height: 260px;
            border-radius: 6px;
            box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
            text-decoration: none;
            color: $color-text;
            &.schedule {
                padding: 10px 20px;
                .schedule-title {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    color: $color-text;
                    margin-bottom: 10px;
                }
                .schedule-observations {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    color: $color-text;
                    margin-bottom: 10px;
                    margin-top: 20px;
                }
                .schedule-text {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: $color-text;
                }
            }
            .contact-detail-header {
                padding: 10px 20px;
                height: 60px;
                border-radius: 6px 6px 0px 0px;
                .position {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                    color: $color-text;
                }
                .name {
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 20px;
                    color: $color-text;
                }
            }

            .contact-detail-body {
                @include flexbox(100%, column, space-between, center, wrap);

                background-color: $color-white;
                padding: 10px 20px;
                height: 198px;
                border-radius: 0 0 10px 10px;

                .address,
                .email,
                .phone {
                    @include flexbox(100%, row, space-between, center, wrap);
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 15px;
                    a {
                        color: $color-text !important;
                        &:hover {
                            color: $color-primary-hover !important;
                        }
                        &:active {
                            color: $color-secondary;
                        }
                    }
                }
            }
        }
    }
}
