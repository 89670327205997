@import "@backoffice/scss/index.scss";
.spaces {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 30px;
        // border-bottom: 1px solid $color-primary;
    }
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }
    .types {
        padding-top: 0px;
    }
    .btn-grid-end {
        @include btn-grid-end();
    }
    .filters-wrapper {
        @include flexbox(100%, row, flex-start, center);
        .space-search {
            padding-top: 22px;
        }
    }
}
