@import "@backoffice/scss/index.scss";

.tab-administrator {
    padding: 24px;
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }

    .end-button-wrapper {
        @include btn-end();

        .add-administrator-button {
            margin: 10px;
            max-width: 300px;
            width: 100%;

            @media screen and (max-width: $media-medium) {
                max-width: none;
            }
        }
    }

    .administrator-form {
        margin: 10px 0px;

        .row {
            flex-wrap: wrap;
        }

        .weekdays-checbox-wrapper {
            @include flexbox(100%, row, flex-start, flex-start, wrap);
            margin: 20px;

            .weekdays-check {
                @include flexbox(125px, column, flex-start, flex-start);
            }
        }

        .partialTime-check {
            @include flexbox(100%, column, flex-start, flex-start);
            padding: 10px;
            padding-top: 27px;
        }
    }

    .btn-end {
        @include btn-end();

        button {
            margin: 10px;
            max-width: 300px;
            width: 100%;

            @media screen and (max-width: $media-medium) {
                max-width: none;
            }
        }
    }
}