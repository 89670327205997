@import "@backoffice/scss/index.scss";
.incidents-tenant-detail {
  @include max-container-width();
  @include general-padding-page();
  width: 100%;

  @media screen and (max-width: 990px) {
    padding-bottom: 20px;
  }

  .content-title {
    padding: 0px;
  }
  .title {
    @include title(18px, $color-text, left, 0px, normal, bolder);
    margin-bottom: 30px;
  }
  .description {
    @include title(12px, $color-text, left, 0px, normal, normal);
    margin-bottom: 20px;
  }
  .return {
    @include return();
    margin-bottom: 30px;
  }
  .tabs {
    border-bottom: 2px solid $color-white-clear;
  }
  .tab-button {
    @include tab-button();
    background-color: $color-white;
    margin-bottom: -2px;

    &:focus {
      outline: none;
    }
  }

  .fixed-label-comments {
    width: 100%;
    margin-bottom: 10px;
    .fixed-label {
      @include flexbox(100%, row, flex-start, center);
      height: 38px;
      padding: 0 10px;
      box-sizing: border-box;
      border-radius: 5px;
      ion-icon {
        font-size: 22px;
        margin-right: 10px;
      }
      p {
        width: calc(100% - 35px);
        line-height: 16px;
        font-size: 12px;
      }
      &.warning {
        background-color: rgba(214, 164, 67, 0.12);
        color: #916203;
      }
    }
  }

  .tabs-wrapper {
    width: 100%;
    padding: 0px;
    @media screen and (max-width: $media-medium) {
      padding: 0px;
    }
    .tab-general {
      .contact {
        @include flexbox(auto, column, space-between, center, nowrap, center);
        .left {
          @include flexbox(
            100%,
            column,
            space-between,
            flex-start,
            nowrap,
            flex-start
          );
          * {
            margin-bottom: 10px;
          }
        }
      }
      .right {
        @include flexbox(100%, column, space-between, center, nowrap, center);
      }
      .status-wrapper {
        @include flexbox(152px, column, space-between, center, nowrap, center);
        div {
          margin-bottom: 10px;
        }
        .status {
          @include flexbox(152px, row, center, center, wrap);
          border-radius: 0px 10px;
          height: 30px;
          font-size: 12px;
          color: $color-text;

          &.progress {
            background-color: $color-state-progress;
          }
          &.pending {
            background-color: $color-state-pending;
          }
          &.resolved {
            background-color: $color-state-resolved;
          }
        }
        .subStatus {
          padding: 10px 15px;
          background-color: $color-secondary;
          color: white;
          border-radius: 8px;
          font-size: $font-size-sm;
          width: 100%;
          text-align: center;
        }
        .no-subStatus {
          background-color: white;
        }
      }
    }
  }
}
