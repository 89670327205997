@import "@backoffice/scss/index.scss";
.booking-tenant-create {
  @include max-container-width();
  @include general-padding-page();
  width: 100%;

  .return {
    width: 100%;
    @include return();
    margin-bottom: 30px;
    display: flex;

    .step {
      flex-grow: 2;
      display: flex;
      justify-content: center;

      &-title {
        @include title(18px, $color-text, left, 1px, normal, bolder);
      }
    }
  }

  .col-xl-6,
  .col-xl-4,
  .col-xl-12 {
    padding: 0;
  }

  .booking-detail-form {
    .booking-tenant-detail-info {
      @include flexbox(100%, row, flex-start, flex-start, wrap);
      .col-xl-4 {
        padding: 0;
      }
      .booking-space-img {

        margin-top: 10px;
        margin-right: 20px;
        padding: 0px;

        .file-preview {
          justify-content: flex-start;
          img {
            border-radius: 8px;
            max-width: 600px;
            width: 100%;
          }
        }
      }
      
      .booking-info {
        padding: 0px 20px;
        margin-bottom: 40px;

        .icon-wrapper {
          margin-bottom: 20px;
          .icon-row {
            @include flexbox(100%, row, flex-start, center, wrap);
            margin: 10px 10px 10px 0px;
            .icon {
              @include flexbox(20px, column, center, flex-start, wrap);
              color: $color-secondary;
            }
            .icon-text {
              @include flexbox(
                calc(100% - 60px),
                column,
                center,
                flex-start,
                wrap
              );
              margin-left: 10px;
              font-size: 14px;
            }
          }
        }
        .title {
          @include title(14px, $color-text, left, 1px, normal, bolder);
          margin-bottom: 10px;
          padding-bottom: 10px;
        }
        .description,
        .instructions {
          @include title(14px, $color-text, left, 0px, normal, normal);
          margin-bottom: 20px;
        }
      }
    }

    .row {
      flex-wrap: wrap;
    }
    .row {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        display: flex;
        align-items: center;
        margin-bottom: 0;
        .combo-wrapper {
          margin-left: 20px;
          margin-bottom: 0;
        }
      }

      .legend-wrapper {
        display: flex;

        @media screen and (max-width: $media-small) {
          @include flexbox(100%, column, flex-start, flex-start, nowrap);
        }
        .legend-square-wrapper {
          @include flexbox(auto, row, flex-end, center);
          margin-right: 15px;

          .legend-square {
            width: 13px;
            height: 13px;
            margin: 2px 10px 1px 0;
            border-radius: 2px;
            &.today {
              background-color: $color-success-bg;
            }
            &.available {
              background-color: $color-white-clear;
            }
            &.blocked {
              background-color: $color-primary-variant3;
            }
            &.selected {
              background-color: $color-secondary;
            }
            &.reserved {
              background-color: $color-secondary;
            }
          }
          .legend-text {
            height: 16px;
            margin: 0 0 0 0;
            font-size: 13px;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: normal;
            color: $color-blue-dark;
          }
        }
      }
    }

    .title-space {
      @include title(14px, $color-text, left, 0px, normal, bolder);
      margin-bottom: 5px;
    }
    .description {
      @include title(12px, $color-text, left, 0px, normal, normal);
      margin-bottom: 20px;
    }
    .col-xl-3,
    .col-xl-2,
    .col-md-9 {
      padding: 0px;
    }
    .space-img-wrapper {
      @include flexbox(100%, row, flex-start, flex-start, wrap);
      .space-img {
        @include flexbox(310px, column, center, center, wrap);
        margin: 10px 20px 10px 0px;
        @media screen and (max-width: $media-medium) {
          @include flexbox(100%, column, center, center, wrap);
        }
        .img-wrapper {
          @include custom-img-wrapper(300px, 300px);
          @media screen and (max-width: $media-x-small) {
            @include custom-img-wrapper(100%, auto);
          }
          margin: 0;
          &:hover {
            cursor: pointer;
          }
        }
        .space-name {
          margin-top: 10px;
          font-size: 12px;
        }
        &.active {
          .img-wrapper {
            img {
              box-shadow: 0 4px 6px 0 rgba(143, 143, 143, 0.5);
              border: 2px solid rgba(143, 143, 143, 0.5);
            }
          }
          .space-name {
            margin-top: 10px;
            font-weight: bolder;
          }
        }
      }
    }
    .peopleNumber-wrapper {
      @include flexbox(100%, row, space-between, center);
      .icon {
        .material-icons {
          font-size: 30px;
          cursor: pointer;
          &:hover {
            color: $color-primary-hover;
          }
        }
      }
    }
    .date-wrapper {
      @include date-wrapper();
    }
    .calendar-wrapper {
      @include flexbox(100%, row, space-between, center, wrap, center);

      .scheduleMonthComponent,
      .scheduleDayComponent {
        margin-bottom: 10px;

        .e-schedule {
          border-color: $color-primary-disabled;
          .e-schedule-toolbar {
            margin: 0;
            border-bottom: 1px solid $color-primary-disabled;
            .e-toolbar-right {
              display: none;
            }
            .e-toolbar-items {
              background-color: $color-white;
              .e-tbar-btn {
                background-color: $color-white;
              }
            }
          }
          .e-btn:hover,
          .e-btn:focus,
          .e-css.e-btn:hover,
          .e-css.e-btn:focus {
            box-shadow: none;
            span {
              color: $color-secondary;
            }
          }
          .e-month-view,
          .e-month-agenda-view {
            .e-appointment-wrapper {
              position: relative;
              .e-appointment {
                border: none;
                color: $color-text;
                width: 100% !important;
              }
              .e-appointment:focus,
              .e-appointment:active {
                box-shadow: none !important;
              }
            }
            .e-date-header-wrap {
              table td {
                background-color: $color-white;
                border-color: $color-primary-disabled;
                border-style: solid;
                border-width: 0px 0px 1px 0px;
                color: $color-text;
                text-align: left;
                vertical-align: middle;
                padding: 0 10px;
              }
              .e-current-day {
                color: $color-secondary;
                font-weight: 500;
              }
            }
            .e-current-date {
              background-color: $color-white !important;
              .e-date-header {
                background-color: $color-success-bg;
              }
            }
            .selected-date {
              border: 2px solid $color-secondary !important;
              .e-date-header {
                color: $color-text;
                border-radius: 50%;
                height: 20px;
                width: 20px;
                background-color: $color-white;
              }
            }
            .e-work-cells {
              background-color: $color-primary-variant3;
              border-color: $color-primary-disabled;
              padding: 5px;
              cursor: no-drop;
            }
            .e-read-only-cells {
              cursor: no-drop !important;
              background-color: #f1f1f1 !important;
            }
            .e-work-days {
              background-color: $color-white;
              cursor: pointer;
            }
          }
          .e-vertical-view {
            .e-time-cells-wrap {
              .e-schedule-table {
                height: 100%;
                tr {
                  height: auto;
                  .e-time-cells,
                  .e-time-slots {
                    @include flexbox(
                      100%,
                      column,
                      center,
                      center,
                      nowrap,
                      center
                    );
                    height: 100%;
                  }
                }
              }
            }
            .e-content-wrap {
              .e-schedule-table {
                height: 100%;
                tr {
                  height: auto;
                  .e-work-cells {
                    @include flexbox(
                      100%,
                      column,
                      center,
                      center,
                      nowrap,
                      center
                    );
                    height: 100%;
                  }
                }
              }
            }
            .e-header-cells {
              height: 45px;
            }
            .e-appointment:focus,
            .e-appointment:active {
              box-shadow: none !important;
            }
            .e-appointment-border {
              box-shadow: none !important;
            }
            .e-work-cells:not([class~="e-work-hours"]) {
              background-color: $color-primary-variant3;
              border-color: $color-primary-variant3;
              cursor: no-drop;
            }
            .e-work-hours {
              background-color: $color-white;
              border-color: $color-primary-disabled;
              cursor: pointer;
              user-select: all;
            }
            .e-selected-cell[class~="e-work-hours"] {
              background-color: $color-secondary;
              border-color: $color-secondary;
            }
          }
          .e-table-container {
            .e-content-table {
              cursor: pointer;
            }
          }
        }
      }
    }
    .e-toolbar
      .e-toolbar-pop
      .e-toolbar-item
      .e-tbar-btn.e-btn
      .e-icons.e-btn-icon {
      color: $color-primary;
    }
    .e-schedule .e-schedule-toolbar .e-active-view .e-tbar-btn-text,
    .e-schedule .e-schedule-toolbar .e-active-view .e-icons {
      color: $color-primary;
      font-weight: 500;
    }
  }
  .btn-end {
    @include btn-end();
  }
}
