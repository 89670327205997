@import "@backoffice/scss/index.scss";
.incidents-provider-detail {
  @include max-container-width();
  @include general-padding-page();
  width: 100%;

  @media screen and (max-width: 990px) {
    padding-bottom: 20px;
  }

  .title {
    @include title(18px, $color-text, left, 0px, normal, bolder);
    margin-bottom: 5px;
  }
  .title-uploader {
    @include title(18px, $color-text, left, 0px, normal, bolder);
    margin-top: 15px;
    margin-bottom: 0px;
  }
  .subtitle-uploader {
    @include title(10px, $color-gray-clear, left, 0px, normal, bolder);
  }
  .description {
    @include title(12px, $color-text, left, 0px, normal, normal);
    margin-bottom: 20px;
  }
  .return {
    @include return();
  }
  .tabs {
    border-bottom: 2px solid $color-white-clear;
  }
  .tab-button {
    @include tab-button();
    background-color: $color-white;
    margin-bottom: -2px;
    &:focus {
      outline: none;
    }
  }
  .tabs-wrapper {
    width: 100%;
    @media screen and (max-width: $media-medium) {
      padding: 0px;
    }
    .tab-general {
      .contact {
        @include flexbox(auto, column, space-between, center, nowrap, center);
        .left {
          @include flexbox(
            100%,
            column,
            space-between,
            flex-start,
            nowrap,
            flex-start
          );
          * {
            margin-bottom: 10px;
          }
        }
      }
      .right {
        @include flexbox(100%, column, space-between, center, nowrap, center);
      }
      .status-wrapper {
        @include flexbox(152px, column, space-between, center, nowrap, center);
        div {
          margin-bottom: 10px;
        }
        .status {
          @include flexbox(152px, row, center, center, wrap);
          border-radius: 0px 10px;
          height: 30px;
          font-size: 12px;
          color: $color-text;

          &.progress {
            background-color: $color-state-progress;
          }
          &.pending {
            background-color: $color-state-pending;
          }
          &.resolved {
            background-color: $color-state-resolved;
          }
        }
        .subStatus {
          padding: 10px 15px;
          background-color: $color-secondary;
          color: white;
          border-radius: 8px;
          font-size: $font-size-sm;
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
