@import "@backoffice/scss/index.scss";
.userdetail {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .return {
        @include return();
    }

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        padding-left: 20px;
        margin-bottom: 30px;
    }

    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        padding-left: 20px;
        margin-bottom: 30px;
    }

    .col-lg-1 {
        .input-container .input-control {
            padding-right: 0.5em;
        }
    }

    .btn-end {
        @include btn-end();
        button {
            width: 100%;
        }
        .button-save {
            margin: 10px;
        }
    }

    .userdetail-form {
        .row {
            flex-wrap: wrap;
        }
    }

    .operator-form {
        .row {
            flex-wrap: wrap;
        }
    }

    .contactperson-form {
        padding-bottom: 20px;
        .search {
            padding-top: 16px;
        }
        .filters-wrapper {
            @include flexbox(100%, row, space-between, center);
        }
    }

    .add-button {
        width: 100%;
    }
}
