@import "@backoffice/scss/index.scss";

.modal-delete_footer {
  margin-top: 2em;

  .button-main-wrapper {
    @include flexbox(100%, row, flex-end, center);

    button {
      width: 100%;
      margin: 10px;
    }
  }
}

.modal-delete_footer-title {
  @include title(18px, $color-text, left, 0px, normal, bolder);
  margin-bottom: 5px;
}
