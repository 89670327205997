@import "@backoffice/scss/index.scss";
.news-tenant {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 1px, normal, bolder);
        margin-bottom: 10px;
        padding-bottom: 10px;
    }

    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .filters-wrapper {
        @include flexbox(100%, row, flex-start, center);
    }

    .search {
        padding: 0px;
    }

    .card-list-wrapper {
        @include flexbox(100%, row, flex-start, center, wrap);
        padding: 0px;
        @media screen and (max-width: $media-medium) {
            padding: 0px;
        }
        .news-tenant {
            width: calc(50% - 20px);
            padding: 0;
            margin: 10px 20px 10px 0px;
            @media screen and (max-width: $media-medium) {
                width: 100%;
                margin: 10px 0px 10px 0px;
            }
        }
    }
}
