@import "@backoffice/scss/index.scss";

.tab-appearance {
    padding: 24px;
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }

    .appearance-form {
        .row {
            flex-wrap: wrap;
        }
    }

    .btn-end {
        @include btn-end();
    }

    .appearance-filePreview-wrapper {
        @include flexbox(auto, row, flex-start, center);
        margin-bottom: 30px;

        .background-img-wrapper {
            @include custom-img-wrapper(400px, 250px);
            margin-bottom: 10px;
            img {
                cursor: pointer;
            }
        }

        .img-wrapper-horizontal {
            @include img-wrapper-horizontal();
            margin-bottom: 10px;
            img {
                cursor: pointer;
            }
        }

        .img-wrapper {
            @include img-wrapper();
            margin-bottom: 10px;
            img {
                cursor: pointer;
            }
        }
        .inputfile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }

    .col-lg-2 {
        padding: 0;
    }

    .button-upload {
        @include flexbox(100%, row, flex-start, center);
        text-align: center;
        color: $color-green-dark;
        border-radius: 5px;
        padding: 0;
        cursor: pointer;
        transition: 0.2s ease all;
        &:hover {
            color: $color-green-clear;
        }
        label {
            @include flexbox(100%, row, center, center);
            text-align: center;
            margin: 10px;
        }
    }
    .button-delete {
        margin: 10px;
        button {
            width: 100%;
        }
    }
}
