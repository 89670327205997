@import "../../../scss/index.scss";
@import "../../../../src/components/FormComponents/Input/Input.scss";

.inputAutoSuggest {
    @include flexbox(auto, column, flex-start, flex-start);
    position: relative;

    .inputLabel {
        margin: 0 6px 8px 0;
        padding-left: 0;
        color: $color-secondary-variant;
        font-weight: 600;
        font-size: 12px;
    }

    .input-control {
        width: 100%;
        height: 32px;
        border-radius: 16px;
        border: solid 1px $color-secondary-variant;
        padding: 0.25em 0.6em;
        box-sizing: border-box;
        font-size: 14px;
        color: $color-blue-dark;
        background-color: $color-white !important;

        @media screen and(min-width: $media-medium) {
            background-color: #ffffff;
        }
        @media screen and(min-width: $media-large) {
            border-radius: 45px;
        }
        &:focus {
            outline: none;
        }

        &.input-outline {
            // margin-top: 5px;
            border: 1px solid $color-gray-clear;

            &:focus {
                border-width: 2px;
            }

            &.input-error {
                border: 2px solid $color-red;
            }
        }
    }

    .suggestedResults {
        position: absolute;
        border-radius: 5px;
        background: $color-white;
        color: $color-blue-dark;
        width: auto;
        min-width: 275px;
        height: auto;
        top: 57px;
        left: 0;
        z-index: 9999;

        &.backoffice-search {
            left: -24px;
            top: 53px;
            border-radius: 4px;
            box-shadow: 0 2px 4px 0 $color-gray;
            min-width: 302px;
            max-height: 228px;
            overflow-y: scroll;
        }
        .results-list {
            &__item {
                padding-bottom: 8px;
                padding-top: 8px;
                color: $color-text;
                font-size: 14px;
                line-height: 20px;
                @include flexbox(100%, column, flex-start, flex-start);
                border-bottom: 1px $color-secondary solid;
                &:hover {
                    cursor: pointer;
                    background-color: $color-secondary;
                    color: $color-text;
                }
                small {
                    padding: 0 0.5rem;
                }
            }
        }
    }
    //IN FORM GRID
    &.in-form {
        width: 100%;

        .suggestedResults {
            left: 0.1em;
            top: 3.5em;
            width: 340px;
            height: 200px;
            border-width: 1.5px;
        }
        ::-webkit-input-placeholder {
            /* Edge */
            color: $color-gray !important;
            font-size: 14px;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            color: $color-gray !important;
            font-size: 14px;
        }

        ::placeholder {
            color: $color-gray !important;
            font-size: 14px;
        }
    }
}
