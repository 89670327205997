@import "@backoffice/scss/index.scss";
.settings {
    padding: 24px;
    width: 100%;

    .btn-end {
        @include btn-end();
        button{
            width: 100%;
        }
    }
    
    .title {
        @include title(18px, $color-text, left, 1px, normal, bolder);
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-primary;
    }

    .subtitle {
        @include title(18px, $color-text, left, 1px, normal, bolder);
        margin-bottom: 10px;
    }

    .info {
        @include title(12px, $color-text, left, 1px, normal, normal);
        margin-bottom: 10px;
        font-style: italic;
    }

    .file-name {
        overflow-wrap: break-word;
        word-wrap: break-word;
        max-height: 20px;
    }

    .settings-form {
        .row {
            flex-wrap: wrap;
        }
        .btn-end {
            @include flexbox(100%, row, flex-end, center);
        }
    }

    .settings-filePreview-wrapper {
        @include flexbox(auto, row, flex-start, center);
        .img-wrapper-horizontal {
            @include img-wrapper-horizontal();
            margin-bottom: 10px;
            img {
                cursor: pointer;
            }
        }
        
        .img-wrapper {
            @include img-wrapper();
            margin-bottom: 10px;
            img {
                cursor: pointer;
            }
        }
        .inputfile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
    }

    .button-upload {
        @include flexbox(auto, row, flex-start, center);
        text-align: center;
        //border: 1px $color-green-clear solid;
        color: $color-green-dark;
        border-radius: 5px;
        padding: 0;
        margin: 0;
        cursor: pointer;
        width: auto;
        transition: 0.2s ease all;
        &:hover {
            color: $color-green-clear;
            //background-color: $color-green-clear;
        }
        label {
            @include flexbox(auto, row, center, center);
            text-align: center;
        }
    }
}
