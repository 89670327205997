@import "@backoffice/scss/index.scss";

.modal-lg {
  min-width: 65%;
}

.popUpPreview {
  width: 100%;
  display: flex;
  flex-direction: column;

  &-closeTab {
    display: flex;
    justify-content: flex-end;

    &-icon {
      cursor: pointer;
      user-select: none;

      &:hover {
        color: $color-secondary;
      }
      &:active {
        color: $color-text;
      }
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-chooseTabs {
      display: flex;
      justify-content: center;

      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #b8b8b8;

      &-web {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid #b8b8b8;

        width: 200px;
        height: 40px;
        margin: 20px;
        margin-right: 0;
        cursor: pointer;

        &:hover {
          color: #1f0f7b;
          border-color: #1f0f7b;
        }
      }
      &-app {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-bottom: 3px solid #b8b8b8;

        width: 200px;
        height: 40px;
        margin: 20px;
        margin-left: 0;
        cursor: pointer;

        &:hover {
          color: #1f0f7b;
          border-color: #1f0f7b;
        }
      }
    }

    &-image {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &-laptop {
        width: 900px;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        &-top {
          display: flex;
          width: 100%;
        }
        &-midle {
          width: 100%;
          max-height: 73.5%;
          display: flex;
          align-items: space-between;
          justify-content: space-between;

          &-content {
            width: 100%;
            img {
              max-width: 270px !important;
            }
          }
        }

        &-bottom {
          width: 100%;
        }

        @media screen and (max-width: 1450px) {
          width: 600px;
          height: 400px;
        }

        @media screen and (max-width: 980px) {
          width: 400px;
          height: 270px;
        }
        min-width: 400px;
        min-height: 270px;
      }

      &-mobile {
        width: 150px;
        height: 600px;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;

        &-top {
          display: flex;
          width: 100%;
        }
        &-midle {
          width: 100%;
          max-height: 68%;
          display: flex;
          align-items: space-between;
          justify-content: space-between;

          &-content {
            width: 100%;
            img {
              max-width: 158px !important;
            }
          }
        }

        &-bottom {
          width: 100%;
        }

        min-width: 250px;
        min-height: 270px;
      }
    }
  }
}

.active {
  color: #1f0f7b;
  border-color: #1f0f7b;
}

img {
  max-width: 100%;
  max-height: 100%;
}

.rowNew {
  padding: 0px 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .container {
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: justify;

    .title-new {
      @include title(12px, $color-text, left, 0px, normal, bolder);
      margin-bottom: 5px;
      text-align: flex-start;
    }

    .date-card {
      margin-bottom: 20px;
      font-size: 10px;
    }

    .img-new {
      border-radius: 8px;
      margin-bottom: 30px;
    }

    .content-new {
      max-width: 300px;
      font-size: 12px;
    }

    @media screen and (max-width: 1450px) {
      max-width: 200px;
      .date-card {
        margin-bottom: 10px;
      }
      .img-new {
        margin-bottom: 15px;
      }
      .content-new {
        max-width: 200px;
      }
    }
  }
}
