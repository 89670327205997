@import "@backoffice/scss/index.scss";

.tenantDetail {
  @include max-container-width();
  @include general-padding-page();
  width: 100%;

  p {
    font-size: 12px;
  }

  .return {
    @include return();
  }
  h3 {
    font-size: 12px;
  }
  .title {
    @include title(18px, $color-text, left, 0px, normal, bolder);
    padding-left: 20px;
    margin-bottom: 25px;
  }

  .description {
    @include title(12px, $color-text, left, 0px, normal, normal);
    padding-left: 20px;
    margin-bottom: 30px;
  }

  .end-button-wrapper {
    @include btn-end();

    button {
      width: 100%;
    }
  }

  .row-wrapper-main {
    @include flexbox(100%, row, flex-start, flex-start, wrap);
    padding: 0 15px;

    .row-wrapper {
      @include flexbox(100%, row, space-between, flex-start, wrap, flex-start);
      margin-bottom: 10px;

      @media screen and (max-width: $media-medium) {
        height: 100%;
        min-height: 150px;
      }
    }

    .col-lg-6 {
      margin-bottom: 30px;
    }

    .column-wrapper {
      @include flexbox(100%, column, normal, center, wrap, center);
    }

    .tenant-personal-information {
      margin: 10px;
      width: calc(100% - 10px);

      display: flex;
      flex-direction: column;

      &-row {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;

        &-title {
          @include title(14px, $color-text, left, 0px, normal, bolder);
          margin: 0;
          min-width: 200px;
        }

        &-value {
          @include title(14px, $color-text, left, 0px, normal, normal);
        }
      }
    }

    .container-document {
      .download-link {
        text-decoration: none;
        cursor: pointer;
        color: $color-text;

        &:hover {
          color: $color-secondary;
        }
      }
    }

    .content-info {
      padding: 0px;
      flex: 1;
      margin-left: 20px;
      margin-bottom: 20px;
      &:first-child {
        margin-left: 0px;
      }
      &:last-child {
        @media screen and (max-width: $media-medium) {
          margin-left: 0px;
        }
      }
    }

    .subtitle {
      font-weight: bold;
    }

    .header-wrapper {
      @include flexbox(100%, row, space-between, center);

      .view-all {
        font-size: 14px;
        color: $color-text;
        text-decoration: underline;

        &:hover {
          color: $color-primary-hover;
        }

        &:focus,
        &:active {
          color: $color-secondary;
        }
      }
    }

    .card-tenant {
      @include flexbox(100%, column, space-around, flex-start, nowrap, center);
      background-color: #f3f3f3;
      text-align: center;
      border-radius: 6px;
      padding: 20px;
      height: 133px;

      @media screen and (max-width: $media-medium) {
        margin: 10px;
      }

      &.center {
        align-items: center;
        background-color: $color-secondary;
        color: white;
        margin-left: 20px;
        margin-right: 20px;
      }

      &-row {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        align-items: center;
        justify-content: start;

        &-title {
          @include title(12px, $color-text, left, 0px, normal, bolder);
          margin: 0;
          min-width: 200px;
        }

        &-value {
            @include title(12px, $color-text, left, 0px, normal, normal);
          margin: 0;
        }
      }

      .total-number {
        font-size: 24px;
        font-weight: bold;
        height: 100%;
      }
      .app-access {
        margin: 0px;
      }

      .last-app-access {
        font-size: 10px;
        line-height: 20px;
        margin-top: 5px;
      }

      .total-app-access,
      .total-incidents,
      .total-bookings {
        height: 100%;
      }

      .tenant-address {
        text-align: left;
      }
    }

    .row {
      @include flexbox(100%, row, flex-start, flex-start, wrap);
    }
  }
}
