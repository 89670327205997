@import "@backoffice/scss/index.scss";

.add-person-popup {
	&.container {
		@include flexbox(100%, row, center, center, wrap);
	}

	.btn-end{
		@include btn-end-popup();
	}
}