@import "@backoffice/scss/index.scss";

.add-service-popup {
	&.container {
		@include flexbox(100%, row, center, center, wrap);
	}

	.btn-end {
		@include btn-end-popup();
	}
}