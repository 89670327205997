@import "@backoffice/scss/index.scss";
.documents-tenant {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    height: calc(100vh - 60px);

    .title {
        @include title(18px, $color-text, left, 1px, normal, bolder);
        margin-bottom: 10px;
        margin-top: 10px;
        padding-bottom: 10px;
    }

    .row {
        @include flexbox(100%, row, flex-start, flex-start, wrap);
        padding-inline: 10px;

        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */

        @media screen and (max-width: $media-x-small) {
            max-height: 450px;
        }

        @media screen and (max-width: $media-xx-small) {
            max-height: 320px;
        }
    }

    .row::-webkit-scrollbar {
        display: none;
    }

    .container-document {
        @include flexbox(100%, row, space-between, center, wrap);
        padding: 10px 20px;
        margin: 10px 20px 10px 0px;
        cursor: pointer;
        background-color: $color-white;
        height: 100px;
        border-radius: 10px;
        box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
        text-decoration: none;
        color: $color-text;

        @media screen and (max-width: $media-medium) {
            margin: 10px 0px 10px 0px;
            width: 100%;
        }

        .body-wrapper {
            @include flexbox(70%, column, center, center, nowrap, center);

            .download-link {
                width: 100%;
                margin: 5px 0px;
                font-size: 14px;
                font-weight: bolder;
                word-break: break-all;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            .extension {
                margin: 5px 0px;
                width: 100%;
            }

            .date {
                margin: 5px 0px;
                width: 100%;
            }
        }

        &:hover {
            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
        }

        .arrow-wrapper {
            @include flexbox(30px, column, center, center, wrap);
            height: 100%;
            .material-icons-round {
                font-size: 30px;
            }
        }
    }

    .tab-button {
        @include tab-button();
        background-color: white;
        position: relative;
        z-index: 2;
        width: 120px;
        &:focus {
            outline: none;
        }
    }

    .tabs-wrapper {
        width: 100%;
        padding: 0px;
        @media screen and (max-width: $media-medium) {
            padding: 0px;
        }

        .tabs{
            margin-bottom: 20px;
        }

        .tabs-underline{
            height: 2px;
            background-color: $color-white-clear;
            position: relative;
            z-index: 1;
            bottom: 2px;
        }
    }

    .btn-download-all {
        @include btn-end();
        margin: 10px 0px;
    }

    .searchInput{
        margin-block: 30px;
        max-width: 300px;
    }
}

.tab {
    margin-top: 10px;

    &-buttons{
        margin-bottom: 25px;

        &-btn{
            margin-right: 10px;
        }
    }
}