@import "@backoffice/scss/index.scss";

.contactstaff-popup {
	&.container {
		@include container();

		.contactstaff-form {
			.row {
				flex-wrap: wrap;
			}
		}

		.title {
			@include title(24px, $color-blue-dark, left, 1px, normal, bolder);
			margin-bottom: 10px;
		}

		.title-md {
			@include title(18px, $color-blue-dark, left, 1px, normal, bolder);
		}

		.title-sm {
			@include title(14px, $color-blue-dark, left, 1px, normal, normal);
			margin-bottom: 20px;
		}
		.btn-end {
			@include btn-end-popup();
		}
	}
}
