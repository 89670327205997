@import "@backoffice/scss/index.scss";

.spinner-container {
	width: 100%;
	height: 100%;
	position: fixed;
	opacity: 0.5;
	z-index: 99999;
	// background-color: rgba($color: #000000, $alpha: 0.9);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	div {
		border-top: 0.5em solid $color-primary-hover;
	}
}
