@mixin flexbox($width: 100%, $direction: row, $j: space-between, $a: center, $wr: wrap, $ac: normal) {
    @include displayFlex();

    width: $width;
    flex-direction: $direction;
    -webkit-justify-content: $j;
    justify-content: $j;
    align-items: $a;
    flex-wrap: $wr;
    align-content: $ac;
}

@mixin displayFlex {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

@mixin flex($values) {
    -webkit-box-flex: $values;
    -moz-box-flex: $values;
    -webkit-flex: $values;
    -ms-flex: $values;
    flex: $values;
}

@mixin order($val) {
    -webkit-box-ordinal-group: $val;
    -moz-box-ordinal-group: $val;
    -ms-flex-order: $val;
    -webkit-order: $val;
    order: $val;
}

@mixin grid($columns, $gap) {
    display: grid;
    grid-template-columns: $columns;
    grid-gap: $gap;
}

@mixin container {
    width: 100%;
    height: auto;
    max-width: calc(1440px - 0.8rem);
    padding-top: 2.5rem;
    padding-right: 1.5rem;
    padding-bottom: 0.8rem;
    padding-left: 1.5rem;
    margin: 0 auto;
}

@mixin title($size, $color, $align, $letter, $line: normal, $weight: normal) {
    font-family: $font;
    font-size: $size;
    font-weight: $weight;
    color: $color;
    text-align: $align;
    letter-spacing: $letter;
    line-height: $line;
}

@mixin subTitle($size, $color, $align, $mb) {
    font-family: $font;
    font-size: $size;
    color: $color;
    text-align: $align;
    margin-bottom: $mb;
}

@mixin btn(
    $width: auto,
    $height: 36px,
    $color: $color-white,
    $bgColor: $color-primary,
    $border: 0px solid transparent,
    $align: center,
    $padding: 0 25px,
    $fontWeight: normal,
    $f-size: 12px
) {
    width: $width;
    min-height: $height;
    color: $color;
    font-weight: $fontWeight;
    font-family: $font;
    font-size: $f-size;
    background-color: $bgColor;
    text-align: $align;
    padding: $padding;
    border-radius: 45px;
    border: $border;
    cursor: pointer;
    outline: none;
    display: inline-block;
    transition: 0.3s ease all;
    &:focus {
        outline: none;
        box-shadow: none !important;
    }
}

@mixin sticky($top) {
    position: sticky;
    top: $top;
}

@mixin carousel($height: 325px) {
    height: $height;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 2rem;
    position: relative;
    @media only screen and (min-width: $media-small-land) {
        margin-bottom: 0;
    }
    .carousel {
        height: 100%;
        .carousel-indicators {
            margin-bottom: 1rem;
            .active {
                background-color: $color-green;
            }
            li {
                color: $color-orange;
                width: 25px;
                height: 2px;
                margin-left: 0 !important;
                background-color: $color-white;
                opacity: 1;
            }
        }

        .carousel-control-prev,
        .carousel-control-next {
            opacity: 1;
        }

        .carousel-control.right:hover,
        .carousel-control-next:hover {
            background: none;
        }

        .carousel-control.left:hover,
        .carousel-control-prev:hover {
            background: none;
        }
        .slider-carousel-wrapper {
            .slider-carousel-button {
                cursor: pointer;
                border-radius: 50%;
                color: $color-white;

                span {
                    font-size: 24px;
                }
            }
        }

        .carousel-inner {
            position: relative;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-radius: 13px;
            box-shadow: 2px 2px 8px 0 $color-gray-clear;

            .carousel-item {
                border-radius: 13px;
                img {
                    height: $height;
                    object-fit: cover;
                    width: 100%;
                }
                .empty-img {
                    @include flexbox(100%, row, center, center);
                }
            }

            .carousel .carousel-inner .item,
            .carousel .carousel-inner .carousel-item {
                height: 100%;
                overflow: hidden;
            }
        }
    }
}

@mixin inputLabel($width, $color, $fontSize, $margin, $display) {
    width: $width;
    color: $color;
    font-size: $fontSize;
    margin: $margin;
    display: $display;
    font-family: $font;
}

@mixin inputForm($width, $height, $bg, $color, $border, $padding) {
    width: $width;
    height: $height;
    background-color: $bg;
    color: $color;
    border: $border;
    padding: $padding;
    box-sizing: border-box;
    font-family: $font;
    &:focus {
        outline: none;
    }
}

@mixin icon($color, $bgColor, $pointer) {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $bgColor;
    color: $color;
    font-size: 14px;
    margin-left: 0.5rem;
    cursor: $pointer;
    &:fist-child {
        margin-left: 0;
    }
}

@mixin comboBox($width: auto) {
    width: $width;
    [class*="-control"] {
        height: 30px;
        min-height: 30px;
        border-radius: 0;
        border-color: $color-gray-clear;
        box-shadow: none;
        cursor: pointer;
        font-family: "ferrovial-regular";

        & > [class*="css-"] {
            &:last-of-type {
                background-color: $color-orange;
                height: 30px;
                width: 30px;
                position: absolute;
                top: -1px;
                right: -1px;
                [class*="-indicatorSeparator"] {
                    display: none;
                }
                [class*="-indicatorContainer"] {
                    padding: 0;
                    left: 4px;
                    position: absolute;
                    color: $color-white;
                }
            }
        }
        & > [class*="css-"] {
            &:last-of-type {
                background-color: $color-orange;
                height: 30px;
                width: 30px;
                position: absolute;
                top: -1px;
                right: -1px;
                [class*="-indicatorSeparator"] {
                    display: none;
                }
                [class*="-indicatorContainer"] {
                    padding: 0;
                    left: 4px;
                    position: absolute;
                    color: $color-white;
                }
            }
        }
    }
}

/*  Grid */
/*Mixin Grid*/
@mixin gridTableWrapper {
    padding: 0px;
    max-width: 100%;
    @media screen and (max-width: $media-x-large) {
        overflow-x: scroll;
    }
    .export-excel-wrapper {
        @include flexbox(100%, row, flex-start, center);
        margin-bottom: 25px;
        .export-excel-button {
            width: 100%;
        }
    }
}

@mixin radioButton($background-color, $border-color, $color-hover, $size) {
    + label .e-label {
        padding-left: 28px !important;
    }
    & + label {
        height: $size;
        width: $size;
    }

    & + label::before {
        background-color: $background-color;
        border-color: $border-color;
        width: $size;
        height: $size;
    }
    & + label::after {
        height: $size - 10;
        width: $size - 10;
    }
    &:hover + label::before {
        border-color: $color-hover;
    }
    &:checked:focus + label::before {
        border-color: $color-hover;
    }
    &:checked + label:hover::before {
        border-color: $color-hover;
    }
    &:checked + label::before {
        border-color: $color-hover;
    }
    &:checked + label::after {
        background-color: $color-hover !important;
        color: $color-hover !important;
    }
}

@mixin gridTable() {
    margin: 1rem auto;
    font-family: $font !important;
    color: $color-primary;
    border: none;
    //border-radius: 8px 8px 8px 8px;
    //box-shadow: 0px 0px 8px 0 $color-gray-clear;
    // @media screen and (max-width: $media-x-large) {
    //     width: 1000px !important;
    // }
    .e-toolbar {
        display: none;
    }
    .e-gridheader {
        .e-headerCell {
            border-top: none;
            font-size: $font-size + px;
            font-weight: bold;
            color: $color-primary;
            border-width: 1px;
        }
    }
    .e-gridheader {
        border: none !important;
        border-radius: 0;
        .e-headercontent {
            .e-rowdragheader,
            .e-grouptopleftcell {
                background-color: $color-primary-variant2;
            }
        }
        .e-headercell {
            border: none;
            border-bottom: 1px solid $color-primary-variant3;
            border-width: 1px;
            padding: 8px 10px;
        }
        .e-headertext {
            font-size: $font-size + px;
            color: $color-primary;
            font-weight: bold;
        }
    }

    td.e-rowcell {
        font-size: $font-size-sm;
        color: $color-primary;
        padding: 5px 10px;
        div {
            @include flexbox(100%, row, flex-start, center, nowrap, center);
        }
        // span {
        //     padding: 3px;
        // }
        &[aria-label*="label_importantAlta"] span.material-icons-round {
            color: $color-priority-high;
        }
        &[aria-label*="label_importantNormal"] span.material-icons-round {
            color: $color-priority-medium;
        }
        &[aria-label*="label_importantBaja"] span.material-icons-round {
            color: $color-priority-low;
        }
        .actionTemplate {
            justify-content: flex-end;
        }
        .statusTemplate {
            span {
                height: auto;
                font-size: $font-size + px;
                border-radius: 9px;
                @include flexbox(100%, row, center, center, nowrap, center);
                &[class*="Pending"] {
                    background-color: $color-state-pending;
                }
                &[class*="InProgress"] {
                    background-color: $color-state-progress;
                }
                &[class*="Resolved"] {
                    background-color: $color-state-resolved;
                }
            }
        }
    }

    .e-gridcontent td {
        // border-right: 1.5px solid $color-primary-variant;
        // border-bottom: 1.5px solid $color-primary-variant;
        border-color: $color-primary-variant3;
    }

    .e-gridcontent {
        // border-left: 1.5px solid $color-primary-variant;
    }

    .e-groupcaption {
        background-color: $color-blue-clear;
        font-size: 14px;
        color: white;
        border-top: 1px solid white;
    }

    .e-recordpluscollapse {
        background-color: $color-blue-clear;
        color: white;
        border-top: 1px solid white;
        .e-icons {
            padding-top: 20px;
            color: white;
        }
    }

    .e-recordplusexpand {
        background-color: $color-blue-clear;
        color: white;
        border-top: 1px solid white;
        .e-icons {
            padding-top: 16px;
            color: white;
        }
    }

    .e-rowdragdrop {
        .e-icons {
            padding-top: 16px;
        }
    }

    // .e-row {
    //     .e-icons:not(.e-check) {
    //         padding-top: 50%;
    //     }
    // }

    .e-indentcell {
        background-color: $color-blue-clear;
        color: white;
    }

    table tbody tr:nth-child(odd) {
        font-family: $font;
    }
    table tbody tr td[class^="e-rowcell e-selectionbackground e-active"],
    table tbody tr td[class^="e-rowcell e-focus e-selectionbackground e-active"] {
        background: $color-white;
        color: #333;
        font-family: $font;
    }
    table tbody tr td[class*="e-active"] {
        background: $color-white !important;
    }
    table tbody tr td:not(:last-of-type) {
        // border-right: 1.5px solid $color-primary;
    }
    form table tbody tr td span[class^="e-input-group e-control-wrapper e-input-focus"] {
        border-color: $color-orange !important;
        font-family: $font;
    }
    button {
        font-family: $font;
    }
    a[class^="e-link e-numericitem e-spacing e-currentitem e-active"] {
        background: $color-primary;
        color: $color-white;
        font-family: $font;
    }
}

@mixin gridFont() {
    [class^="e-control"] {
        font-family: $font;
        [class^="e-tbar-btn-text"],
        [class^="e-parentmsgbar"] {
            font-family: $font;
        }
    }
}

@mixin hr($color) {
    margin: 2rem 0;
    background: transparent;
    border-top: 0.1rem solid $color;
}

@mixin tab-button() {
    font-size: 14px;
    font-weight: normal;
    color: $color-text;
    border: none;
    border-bottom: 2px solid $color-white-clear;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.3s ease all;
    @media screen and (max-width: $media-medium) {
        width: 100%;
    }
    &:hover {
        border-color: $color-secondary;
    }
    &.active {
        border-color: $color-secondary;
        color: $color-secondary;
        font-weight: bolder;
    }
    &:first-of-type {
        margin-left: 0;
    }
}

@mixin form-row {
    display: flex;
    flex-wrap: wrap;
}

@mixin form-wrapper {
    margin: 5px;
}

@mixin margin-r($size) {
    margin-right: $size;
}

@mixin margin-l($size) {
    margin-left: $size;
}

@mixin img-wrapper {
    position: relative;
    box-sizing: border-box;
    max-width: 170px;
    max-height: 170px;
    min-width: 170px;
    min-height: 170px;
    width: 170px;
    height: 170px;
    border-radius: 8px;
    margin: 5px;

    @media screen and (max-width: $media-medium) {
        min-width: unset;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        border: none;
        border-radius: 8px;
        transition: all 0.3s ease-in-out;
        &:hover {
            box-shadow: 0 4px 6px 0 rgba(143, 143, 143, 0.5);
            transform: scale(1.03);
        }
    }
}

@mixin custom-img-wrapper($width, $height) {
    position: relative;
    box-sizing: border-box;
    max-width: $width;
    max-height: $height;
    min-width: $width;
    min-height: $height;
    width: $width;
    height: $height;
    border-radius: 8px;
    margin: 5px;

    @media screen and (max-width: $media-medium) {
        min-width: unset;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
        border: 1px solid rgba(143, 143, 143, 0.5);
        border-radius: 8px;
        &:hover {
            box-shadow: 0 4px 6px 0 rgba(143, 143, 143, 0.5);
            border: 2px solid rgba(143, 143, 143, 0.5);
        }
    }
}

@mixin img-wrapper-horizontal {
    position: relative;
    box-sizing: border-box;
    max-width: 300px;
    max-height: 75px;
    min-width: 300px;
    min-height: 75px;
    width: 300px;
    height: 75px;
    border-radius: 8px;
    margin: 5px;

    @media screen and (max-width: $media-medium) {
        min-width: unset;
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
        border: 1px solid rgba(143, 143, 143, 0.5);
        border-radius: 8px;
        &:hover {
            box-shadow: 0 4px 6px 0 rgba(143, 143, 143, 0.5);
            border: 2px solid rgba(143, 143, 143, 0.5);
        }
    }
}

@mixin round-borders($tl, $tr, $br, $bl) {
    -webkit-border-radius: $tl $tr $br $bl;
    -moz-border-radius: $tl $tr $br $bl;
    border-radius: $tl $tr $br $bl;

    /* Prevent background color leak outs */
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

@mixin calendar() {
    .e-schedule {
        border: none;
        border-radius: 10px;
        cursor: pointer;
        .e-appointment {
            cursor: pointer;
        }
        .e-month-view {
            .e-date-header {
                margin: 4px auto;
            }
            .e-more-indicator {
                font-size: 14px;
                color: $color-green-dark;
                font-weight: 500;
            }
        }
        .e-week-view {
            .e-schedule-table {
                .e-time-slots {
                    padding-top: 4px;
                    box-sizing: border-box;
                }
            }
        }
        .e-day-view {
            .e-schedule-table {
                .e-time-slots {
                    padding-top: 4px;
                    box-sizing: border-box;
                }
            }
        }

        .e-schedule-toolbar-container .e-toolbar-items * {
            background-color: $color-primary !important;
            color: white !important;
        }
        .e-more-event-popup {
            .e-subject {
                color: $color-primary !important;
            }
        }
        .e-schedule-toolbar-container {
            background-color: $color-primary !important;
            margin: 0 !important;
            // padding: 0.5rem 0;
            .e-schedule-toolbar {
                box-shadow: none;
                background-color: $color-primary;

                .e-hor-nav {
                    background-color: $color-primary !important;
                    .e-icons {
                        color: white;
                    }
                }

                .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
                    color: $color-primary;
                }

                .e-toolbar-items {
                    background-color: $color-primary;

                    .e-toolbar-left {
                        width: 350px;
                        display: flex;
                        text-transform: capitalize !important;
                        align-items: center;
                        justify-content: space-between;
                        position: relative;
                        .e-tbar-btn {
                            .e-tbar-btn-text {
                                font-family: $font;
                                font-size: 20px;
                                font-weight: bold;
                                color: $color-blue-dark;
                            }
                            .e-btn-icon.e-icon-down-arrow {
                                display: none;
                            }

                            &:hover {
                                box-shadow: none;
                                color: $toryblue;
                                .e-btn-icon {
                                    color: $toryblue;
                                }
                            }
                            &:focus {
                                box-shadow: none;
                            }
                        }
                        .e-prev {
                            display: none;
                        }
                        .e-next {
                            display: none;
                        }
                        .e-date-range {
                            position: absolute;
                            left: 50%;
                            transform: translate(-50%, 0);
                        }
                    }
                    .e-toolbar-center {
                    }
                    .e-toolbar-right {
                        .e-toolbar-item {
                            .e-tbar-btn {
                                height: 40px !important;

                                &:hover,
                                &:active,
                                &:focus {
                                    box-shadow: none !important;
                                }
                                .e-tbar-btn-text {
                                    text-transform: capitalize !important;
                                    color: $color-blue-dark;
                                    padding: 0 1rem;
                                    border-radius: 6px;
                                }

                                span {
                                    transition: 0.2s ease all;
                                }
                            }
                            &:not(.e-active-view) {
                                .e-tbar-btn:hover {
                                    //background-color: $color-orange !important;
                                    & span {
                                        background-color: $color-white !important;
                                        color: $color-blue-dark !important;
                                    }
                                }
                            }
                            &.e-separator {
                                border-right: 1px solid $color-gray-clear;
                            }
                        }
                        .e-active-view {
                            .e-tbar-btn {
                                &:hover,
                                &:active,
                                &:focus {
                                    box-shadow: none !important;
                                }
                                .e-tbar-btn-text {
                                    color: $color-blue-dark !important;
                                    background-color: $color-white !important;
                                }
                            }
                        }
                    }
                }
            }
            //calendar
            .e-header-popup {
                .e-header-calendar {
                    .e-content {
                        .e-cell.e-selected > span {
                            background-color: $color-primary-hover;
                            color: white;
                            border: none;
                            box-shadow: none;
                        }
                    }
                    .e-footer-container {
                        button {
                            color: $color-primary;
                        }
                    }

                    .e-today {
                        .e-day {
                            border-color: $color-primary-hover !important;
                            color: $color-text;
                        }
                        .e-selectd {
                            color: white;
                        }
                    }
                }
            }
            //desplegable año
            .e-header-popup {
                left: 10% !important;
                transform: translate(-45%, 0);
            }
            .e-spinner-pane {
                display: none;
            }
        }
        //table
        .e-table-container {
            .e-header-cells {
                border: none;
                font-family: $font !important;
                font-size: 16px !important;
                font-weight: 600 !important;
                // color: $color-text !important;
                overflow: hidden;
                text-align: center;
                text-decoration: none;
                text-transform: capitalize;
                padding: 10px !important;
                border-bottom: 1px solid $color-white-clear !important;
                border-left: 1px solid $color-white-clear !important;
            }
            .e-date-header-container {
                .e-current-day {
                    background-color: $color-primary-hover;
                    color: white;
                }
            }
            .e-content-wrap {
                //overflow: hidden !important;
                //height: calc(100vh - 200px) !important;
                &::-webkit-scrollbar-thumb {
                    background-color: $color-primary;
                    border-radius: 5px;
                }
                &::-webkit-scrollbar-track {
                    -webkit-box-shadow: inset 0 0 8px $color-primary;
                }
                &::-webkit-scrollbar {
                    width: 8px;
                }

                .e-content-table {
                    .e-work-cells:not(.e-work-hours) {
                        cursor: not-allowed;
                        background-color: $color-disabled;
                    }
                    .e-work-cells {
                        border-color: $color-white-clear;
                        background-color: white;
                        &.e-work-hours {
                            border-color: $color-white-clear;
                            background-color: white;
                            cursor: pointer;
                        }
                        &.e-currentday-color :not(.e-work-hours) {
                            background-color: $color-primary-hover;
                        }
                        &.e-current-date {
                            background-color: $color-primary-hover;
                            .e-date-header {
                                background-color: $color-primary-hover;
                            }
                        }
                        &.e-selected-cell {
                            background-color: $color-success-bg;
                        }
                        &.e-read-only-cells {
                            cursor: not-allowed;
                            background-color: $color-disabled;
                            &.e-current-date {
                                background-color: $color-primary-hover;
                            }
                        }
                        &:hover :not(.e-read-only-cells) :not(.e-appointment) :not(.e-subject) :not(.e-time) {
                            background-color: $color-light-green-dark;
                        }
                    }
                }
            }
            .e-vertical-view {
                .e-current-time {
                    color: $color-green-dark;
                }
                .e-previous-timeline {
                    border-top: 1px dotted $color-green-dark;
                }
                .e-current-timeline {
                    border-top: 1px solid $color-green-dark;
                }
                .e-time-cells-wrap .e-time-cells {
                    padding-top: 12px;
                }
            }
        }
    }
}

@mixin date-wrapper() {
    width: 100%;

    [class*="e-date-wrapper"] {
        height: 30px;
        min-height: 30px;
        border: 1px solid !important;
        border-radius: 45px !important;
        border-color: $color-text !important;
        box-shadow: none;
        cursor: pointer;
        font-family: $font !important;
        font-size: 14px !important;
        margin: 0px !important;
        &::after,
        &::before {
            background: transparent !important;
        }
        & > input {
            padding-left: 0.5rem !important;
        }

        .e-datepicker {
            padding: 3px 0;
        }

        [class*="e-date-icon"] {
            color: $color-primary !important;
            margin-top: 3px !important;
            margin-right: 0.5rem !important;
            &:hover {
                color: $color-primary-hover !important;
            }
        }

        &.e-input-focus {
            height: 29px;
            border-width: 2px !important;
            border-color: $color-primary-hover !important;
            [class*="e-date-icon"] {
                color: $color-primary-hover !important;
            }
        }
    }

    .e-content.e-month {
        .e-schedule-table {
            tbody {
                .e-week-number {
                    color: $color-secondary !important;
                    font-size: 0.7rem;
                }

                .e-cell {
                    &.e-selected {
                        .e-day {
                            background-color: $color-secondary;
                            border: none;
                            box-shadow: inset 0 0 0 2px #fff;
                            color: #fff;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }

    [class*="input-error"] {
        border: 2px solid $color-red !important;
        [class*="e-date-icon"] {
            color: $color-red !important;
        }
    }
}

@mixin return() {
    margin-bottom: 1rem;
    .col-lg-2 {
        padding: 0;
    }
    .go-back {
        @include flexbox(100%, row, flex-start, center);
        font-size: 14px;
        color: $color-text;
        font-weight: bold;
        transition: 0.2s ease all;
        cursor: pointer;
        &:hover {
            text-decoration: none;
            color: $color-secondary;
        }
    }
}

@mixin btn-end {
    @include flexbox(1100px, row, flex-end, center);
    max-width: 1100px;
    padding: 0px;
    position: fixed;
    bottom: 0px;
    z-index: 1;

    @media screen and (max-width: $media-x-large) {
        max-width: calc(100% - 48px);
    }
    @media screen and (max-width: $media-medium) {
        @include flexbox(calc(100% - 48px), row, center, center);
        max-width: none;
    }
    button {
        max-width: 250px;
        min-width: 200px;
        margin: 10px;
        @media screen and (max-width: $media-medium) {
            width: 100%;
            min-width: none;
            max-width: calc(100% - 58px);
        }
    }
}

@mixin btn-grid-end {
    @include flexbox(100%, row, flex-end, center);
    @include max-container-width();
    @media screen and (max-width: $media-x-large) {
        max-width: 100%;
    }
    @media screen and (max-width: $media-medium) {
        @include flexbox(100%, row, center, center);
        max-width: none;
    }
    button {
        max-width: 250px;
        min-width: 200px;
        margin: 10px 0px 10px 20px;
        @media screen and (max-width: $media-medium) {
            width: 100%;
            min-width: none;
            max-width: calc(100% - 58px);
            margin: 10px 20px 10px 20px;
        }
    }
}

@mixin btn-end-popup {
    @include flexbox(100%, row, flex-end, center);
    padding: 0px 15px;
    position: relative;
    bottom: 10px;
    right: 0px;
    z-index: 1;
    button {
        min-width: 250px;
        max-width: 250px;
        @media screen and (max-width: $media-medium) {
            width: 100%;
            min-width: none;
            max-width: none;
        }
    }
}

@mixin white-wrapper {
    @include flexbox(30%, column, center, center, nowrap);
    max-width:600px !important;
    min-width: 300px !important;
    margin: auto;
    padding-inline: 40px;
    padding-block: 80px;
    background-color: rgb(255, 255, 255, 0.8);
    border-radius: 15px;
    max-height: 100%;
    @media screen and (max-height: 530px) {
        @include flexbox(30%, row, center, center);
        padding-block: 40px;
        padding-inline: 20px;
        max-width: unset !important;
        min-width: 300px !important;
        width: 700px;
    }
    .img-wrapper {
        img {
            max-height: 100px;
            margin-bottom: 50px;
            @media screen and (max-height: 530px) {
                margin-bottom: none;
            }
        }
    }
}

@mixin max-container-width {
    max-width: calc(1148px);
    @media screen and (max-width: $media-large) {
        max-width: 100%;
    }
}

@mixin general-padding-page {
    padding: 24px 24px 70px 24px;
    @media screen and (max-width: $media-medium) {
        padding: 24px 24px 124px 24px;
    }
}
