@import "@backoffice/scss/index.scss";
	
.PopUpChangePass{
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 20px;
}

.title {
    @include title(12px, $color-text, left, 0px, normal, bolder);
    margin-bottom: 10px;
}

.divider{
    width: 100%;
    height: 1px;
    margin: 1rem 0;
    overflow: hidden;
    border-top: 1px solid #b4b4b4;
}

.title-error{
    @include title(12px, $color-text, left, 0px, normal, bolder);
    margin-bottom: 10px;
    color: red;
    min-height: 15px;
}
.input-pass{
    max-width: 350px;
}

.changePass-btn{
    margin-top: 20px;
    align-self: center;
}