@import "@backoffice/scss/index.scss";
.buildings {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 25px;
    }
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .btn-grid-end {
        @include btn-grid-end();
    }

    .end-button-wrapper {
        @include btn-end();
        .add-button {
            width: 100%;
            margin: 10px;
        }
        .export-excel-button {
            margin: 10px;
            width: 100%;
        }
    }
}
