@import "@backoffice/scss/index.scss";

.login {
    min-height: 100%;
    .wrapper {
        @include flexbox(100%, row, center, center);
        height: 100vh;
        .white-wrapper {
            @include white-wrapper();
        }
    }
}

.img-login{
    margin-bottom: 50px;
}