@import "../../../scss/index.scss";

.checkbox {
  @include flexbox(100%, column, center, flex-start);
  margin-bottom: 1em;

  .inputCheck {
    position: absolute;
    opacity: 0;

    & + label {
      @include flexbox(100%, row, flex-start, center, nowrap);

      position: relative;
      cursor: pointer;
      font-size: 12px;
      padding: 0;
      font-family: $font;
      color: $color-text;
    }

    // Box.
    & + label:before {
      content: "";
      display: inline-block;
      vertical-align: bottom;
      width: 18px;
      height: 18px;
      margin-right: 10px;
      background: $color-white;
      border-radius: 3px;
      border: 1px solid $color-primary;
    }

    // Box focus
    &:focus + label:before {
      border: 2px solid $color-primary;
    }

    // Box checked
    &:checked + label:before {
      background: $color-primary-hover;
    }

    // Disabled state label.
    &:disabled + label {
      color: #b8b8b8;
      cursor: auto;
    }

    // Disabled box.
    &:disabled + label:before {
      box-shadow: none;
      background: #ddd;
    }

    // Checkmark. Could be replaced with an image
    &:checked + label:after {
      content: "";
      position: absolute;
      left: 4px;
      top: 8px;
      background: $color-white;
      width: 2px;
      height: 2px;
      box-shadow: 2px 0 0 $color-white, 4px 0 0 $color-white, 4px -2px 0 $color-white, 4px -4px 0 $color-white,
        4px -6px 0 $color-white, 4px -8px 0 $color-white;
      transform: rotate(45deg);
    }
  }

  &.isDisabled {
    opacity: 1;

    .inputCheck {
      // Box.
      & + label:before {
        background: $color-disabled;
      }
      // Box checked
      &:checked + label:before {
        background: $color-disabled;
      }
    }
  }
}
