@import "@backoffice/scss/index.scss";

.booking-tenant-detail {
  @include max-container-width();
  @include general-padding-page();
  width: 100%;

  .return {
    padding: 24px 24px 0px 24px;
    @include return();
  }

  .title-detail {
    padding-top: 30px;
    padding-left: 30px;
    @include title(18px, $color-text, left, 1px, normal, bolder);
  }

  .btn-end {
    @include btn-end();
    padding-right: 80px;

    @media screen and (max-width: $media-medium) {
      padding: 0px;
    }

    button {
      margin: 10px;
      max-width: 300px;
      width: 100%;

      @media screen and (max-width: $media-medium) {
        max-width: none;
      }
    }
  }
}

.booking-tenant-detail-info {
  padding: 30px;
  @include flexbox(100%, row, flex-start, flex-start, wrap);
  .col-xl-4 {
    padding: 0;
  }
  .booking-space-img {
    margin-top: 10px;
    margin-right: 20px;
    padding: 0px;

    .file-preview {
      justify-content: flex-start;
      img {
        border-radius: 8px;
        max-width: 600px;
        width: 100%;
      }
    }
  }

  .booking-info {
    padding: 0px 20px;
    margin-bottom: 40px;

    .icon-wrapper {
      margin-bottom: 20px;
      .icon-row {
        @include flexbox(100%, row, flex-start, center, wrap);
        margin: 10px 10px 10px 0px;
        .icon {
          @include flexbox(20px, column, center, flex-start, wrap);
          color: $color-secondary;
        }
        .icon-text {
          @include flexbox(calc(100% - 60px), column, center, flex-start, wrap);
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }
    .title {
      @include title(14px, $color-text, left, 1px, normal, bolder);
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
    .description,
    .instructions {
      @include title(14px, $color-text, left, 0px, normal, normal);
      margin-bottom: 20px;
    }
  }
}
