@import "@backoffice/scss/index.scss";

.resetpassword {
	min-height: 100%;

	.wrapper {
		@include flexbox(100%, row, center, center);
		height: 100vh;

		.white-wrapper {
			@include white-wrapper();
		}
	}

	.resetpassword-form {
		padding: 0px 40px;
		width: 400px;

		.resetpassword-title {
			font-weight: bolder;
			margin-bottom: 15px;
		}

		.form-wrapper {
			margin-bottom: 0.5rem;
			&:nth-child(2) {
				margin-bottom: 0;
			}
		}

		.link-forgotPass {
			text-align: center;
			margin-bottom: 2rem;
			text-decoration: underline;
			font-weight: bolder;
			cursor: pointer;
			font-size: 12px;
	
			&:hover {
				color: $color-primary-hover;
			}
	
			&:active {
				color: $color-secondary;
			}
		}

		.pointer {
			cursor: pointer;
		}

		.button-container {
			button {
				width: 100%;
				margin-bottom: 0.5em;
			}
		}

		.input-container .inputLabel {
			padding-left: 0;
		}
	}
}
