@import "@backoffice/scss/index.scss";

.new-tenant-detail {
  @include max-container-width();
  @include general-padding-page();
  width: 100%;

  .return {
    @include return();
    margin-bottom: 20px;
  }

  .row {
    display: flex;
    justify-content: center;
    width: 100%;

    .container {
      max-width: 600px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: justify;

      .title-new {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 10px;
        text-align: flex-start;
      }

      .date-card {
        margin-bottom: 50px;
      }

      .img-new {
        border-radius: 8px;
        margin-bottom: 50px;
      }
    }
  }
}
