@import "@backoffice/scss/index.scss";
.popupmedia {
    width: 100%;

    .uploader-wrapper {
        @include flexbox(100%, row, flex-start, center);
        padding: 24px;
        margin-bottom: 10px;
    }

    .btn-end {
        @include btn-end-popup();
        button {
            margin: 10px;
            width: 100%;
        }
    }
}
