@import "../../scss/index.scss";
.menu-left {
    width: 250px;
    @media screen and (max-width: $media-medium) {
        width: 100%;
    }
    &-list {
        list-style-type: none;
        margin: 0;
        top: 60px;
        padding-top: 10px;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        width: 250px;
        background-color: $color-menu-bg;
        position: fixed;
        height: calc(100vh - 60px);
        overflow: auto;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.16);
        @media screen and (max-width: $media-medium) {
            width: 100%;
            height: unset;
            position: unset;
        }
        &__item {
            cursor: pointer;
            a {
                padding: 14px 14px 14px 14px;
                display: block;
                color: $color-menu;
                font-size: 14px;
                text-decoration: none !important;

                .item-row {
                    @include flexbox(100%, row, flex-start, center, nowrap);

                    .icon {
                        padding: 0px 10px;
                    }

                    .expand {
                        @include flexbox(29px, row, flex-end, center, nowrap);
                    }
                }
            }
            &:hover {
                text-decoration: none !important;
                background-color: $color-primary-hover;
                a,
                .icon {
                    text-decoration: none !important;
                    color: white;
                }
            }
            &.active:not(:hover) {
                background-color: $color-disabled;
                font-weight: bolder;
                a,
                .icon {
                    color: $color-secondary;
                }
                &:hover {
                    a,
                    .icon {
                        color: white;
                    }
                }
            }
        }
        .mini-menu {
            padding: 16px 16px 16px 16px;
            display: block;
            color: #000;
            cursor: pointer;
            font-size: 16px;
            text-decoration: none !important;
            display: none;
            .icon {
                padding: 0px 10px;
            }
            .icon-float-left {
                position: absolute;
                right: 20px;
            }
            @media screen and (max-width: $media-medium) {
                display: block;
            }
        }
    }
    .ident {
        a {
            margin-left: 45px;
        }
    }

    .display-none {
        display: none;
        @media screen and (min-width: $media-medium) {
            display: block;
        }
    }
    .display {
        display: block;
    }
}
