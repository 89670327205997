@import "@backoffice/scss/index.scss";
.building-detail {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .return {
        @include return();
    }
    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }
    .subtitle {
        @include title(16px, $color-text, left, 0px, normal, normal);
        font-style: italic;
        margin-bottom: 30px;
    }
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 30px;
    }
    .tabs {
        border-bottom: 2px solid $color-white-clear;
    }

    .tab-button {
        @include tab-button();
        margin-bottom: -2px;
        &:focus {
            outline: none;
        }
    }
    .header-wrapper {
        @include flexbox(100%, row, space-between, center);
        .right {
            text-align: right;
            font-size: 14px;

            .location {
                margin-bottom: 5px;
            }

            .maps {
                text-align: center;
                margin-bottom: 2rem;
                text-decoration: underline;
                font-weight: bolder;
                cursor: pointer;
                font-size: 12px;
                color: $color-text;

                &:hover {
                    color: $color-primary-hover;
                }

                &:active {
                    color: $color-secondary;
                }
            }
        }
    }
    .tabs-wrapper {
        width: 100%;
    }
}
