@import "@backoffice/scss/index.scss";
.tab-general {
    padding: 24px;
    width: 100%;
    background-color: white;
    @media screen and (max-width: $media-medium) {
        padding: 24px 0px;
    }
    .col-lg-3,
    .col-sm-9,
    .col-lg-10,
    .col-lg-12,
    .col-sm-3 {
        padding: 0px;
    }
    .incident-title {
        @include title(14px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }
    .incident-subtitle {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }
    .subtitle-uploader {
        @include title(10px, $color-gray-clear, left, 0px, normal, bolder);
    }
    .uploader-wrapper {
        @include flexbox(100%, row, flex-start, center);
        margin-bottom: 20px;
    }
    .btn-download-all {
        @include btn-grid-end();
    }
    .media-wrapper {
        @include flexbox(100%, row, flex-start, flex-start, wrap);
        padding: 10px 0px;
        .media-card {
            cursor: pointer;
            .img-wrapper {
                @include img-wrapper();
            }
            .media-body-wrapper {
                @include flexbox(175px, row, space-between, center, wrap);
                padding: 10px;
                padding-left: 20px;
                .media-name {
                    @include flexbox(100%, column, center, center, wrap);
                    word-break: break-all;
                    font-size: 10px;
                }
                .material-icons-round {
                    @include flexbox(25px, column, flex-end, flex-end, wrap);
                    &:hover {
                        color: $color-primary-hover;
                    }
                }
            }
        }
    }

    .end-button-wrapper {
        @include btn-end();
        .add-administrator-button {
            margin: 10px;
            max-width: 300px;
            width: 100%;
            @media screen and (max-width: $media-medium) {
                max-width: none;
            }
        }
    }

    .row {
        flex-wrap: wrap;
    }

    .incident-wrapper {
        @include flexbox(100%, row, space-between, flex-start);
        margin-bottom: 10px;
    }

    .btn-end {
        @include btn-end();
        button {
            margin: 10px;
            max-width: 300px;
            width: 100%;
            @media screen and (max-width: $media-medium) {
                max-width: none;
            }
        }
    }
}
