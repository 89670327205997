@import "@backoffice/scss/index.scss";

@media screen and (max-width: 990px) {
  .fixed-label_container {
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
  }
  .information{
    height: auto !important;
    padding: 15px !important;
  }
}

.tab-comments {
  display: flex;
  height: 75vh !important;
  padding: 0;
  padding-top: 20px;
  @media screen and (max-width: 990px) {
    height: 65vh !important;
  }

  &-conversations {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: 35%;
    border-right: 1px solid grey;
    @media screen and (max-width: 990px) {
      width: 100%;
    }

    &-titleHeader {
      width: 100%;
      display: flex;
      justify-content: space-between;

      &-title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 0px;
      }
      &-information {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 0px;

        position: relative;
        cursor: pointer;

        &:hover {
          color: $color-secondary;
        }
        &:hover .tooltiptext {
          visibility: visible;
        }

        .tooltiptext {
          @include title(12px, white, left, 0px, normal, normal);
          width: 250px;
          visibility: hidden;
          background-color: $color-secondary;
          text-align: justify;
          border-radius: 10px;
          margin-left: 10px;
          padding: 10px;

          /* Position the tooltip */
          position: absolute;
          z-index: 1;
          top: -5px;
          left: 105%;
        }
        .tooltiptext::after {
          content: " ";
          position: absolute;
          top: 20%;
          right: 100%; /* To the left of the tooltip */
          margin-top: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparent $color-secondary transparent transparent;
        }
      }
    }

    &-card {
      display: flex;
      box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
      padding: 20px;
      border-radius: 10px;
      margin-block: 10px;
      cursor: pointer;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

      &:hover {
        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
      }

      &-content {
        width: 85%;
        display: flex;
        flex-direction: column;
        align-items: start;
        overflow: hidden;

        &-top {
          width: 100%;
          display: flex;
          flex-flow: row wrap;
          justify-content: space-between;

          &-title {
            @include title(12px, $color-text, left, 0px, normal, bolder);
          }
        }

        &-subtitle {
          @include title(12px, $color-text, left, 0px, normal, normal);
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis !important;

          &-empty {
            @include title(12px, $color-text, left, 0px, normal, normal);
            color: grey;
          }
        }
      }

      &-icon {
        width: 15%;
        display: flex;
        align-items: center;
        justify-content: end;
      }
    }

    &-space {
      height: 40px;
    }
  }

  &-conversation {
    display: flex;
    flex-direction: column;
    padding-inline: 20px;
    padding-bottom: 0;
    width: 65%;

    @media screen and (max-width: 990px) {
      width: 100%;
    }

    &-information {
      display: flex;
      align-items: center;
      justify-content: start;
      background-color: $color-secondary;
      border-radius: 5px;
      padding: 0.5rem;
      color: white;
      margin-bottom: 10px;

      &-title {
        display: flex;
        align-items: center;
        justify-content: start;

        span {
          font-size: 15px;
          margin-right: 5px;
        }

        font-size: 12px;
        margin: 0;
        margin-left: 5px;
      }
    }

    &-header {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      margin-bottom: 20px;
      height: 32px;
      width: 100%;
      border-bottom: 1px solid $color-secondary;

      &-title {
        @include title(16px, $color-secondary, left, 0px, normal, bolder);
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (min-width: 990px) {
          justify-content: center;
        }

        &-icon {
          cursor: pointer;
          align-self: flex-start;
        }
      }
    }

    &-mesages {
      height: calc(100% - 80px);
      overflow-y: scroll;
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */

      &-external {
        @include flexbox(100%, row, flex-start, center, wrap);
        margin-bottom: 10px;
        border-top: none;

        .comment-wrapper {
          @include flexbox(50%, row, flex-start, flex-start, wrap);
          padding: 12px 30px;
          border-radius: 10px;
          background-color: #f7f7f7;

          @media screen and (max-width: $media-medium) {
            @include flexbox(100%, row, flex-start, flex-start, wrap);
          }
          .header-wrapper {
            @include flexbox(100%, row, flex-start, center, wrap);
            margin-bottom: 10px;

            .date-comment {
              font-size: 12px;
              font-weight: bolder;
              margin: 0px;
              color: #8b8b8b;
            }
            .user-comment {
              margin-left: 20px;
              font-size: 12px;
              color: $color-gray-clear;
            }
          }
          .comment-text {
            @include flexbox(100%, row, flex-start, center, wrap);
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 120%;
          }
        }
      }

      &-provider {
        @include flexbox(100%, row, flex-end, center, wrap);
        margin-bottom: 10px;
        border-top: none;

        .comment-wrapper {
          @include flexbox(50%, row, flex-start, flex-start, wrap);
          padding: 12px 30px;
          border-radius: 10px;
          background: #ffffff;
          border: 1px solid #000000;
          border-radius: 6px;

          @media screen and (max-width: $media-medium) {
            @include flexbox(100%, row, flex-start, flex-start, wrap);
          }
          .header-wrapper {
            @include flexbox(100%, row, flex-end, center, wrap);
            margin-bottom: 10px;

            .date-comment {
              font-size: 12px;
              font-weight: bolder;
              margin: 0px;
              color: #8b8b8b;
            }
            .user-comment {
              margin-left: 20px;
              font-size: 12px;
              color: $color-gray-clear;
            }
          }

          .comment-text {
            @include flexbox(100%, row, flex-end, center, wrap);
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 300;
            font-size: 12px;
            line-height: 120%;
          }
        }
      }
    }

    &-mesages::-webkit-scrollbar {
      display: none;
    }

    &-input {
      margin-top: 8px;
      height: 32px;
      width: 100%;
    }
  }
}

.displayNone {
  display: none;
}
.removeBorder {
  border: none;
}