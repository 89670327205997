@import "../../scss/index.scss";

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
.fade {
  transition: opacity 0.15s linear;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}
.modal {
  position: fixed;
  top: 60px !important;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-header {
  @include flexbox(100%, row, space-between, center, $ac: center);
  border-radius: 6px 6px 0 0;
  color: $color-text;
  background-color: $color-primary-variant;
  padding-left: 2em;
  border-bottom-color: transparent;
  font-size: 18px;

  &__add {
    background-color: $color-secondary;
    color: white;
  }
  &__blue {
    background-color: $color-blue-dark;
    color: white;
  }
  &__edit {
    background-color: $color-secondary;
  }
  &__warning {
    background-color: $color-orange-clear;
  }
  &__danger {
    background-color: $color-error;
    color: $color-white-clear;
  }
  &__transparent {
    background-color: transparent;
  }

  .close {
    padding: 0;
    text-shadow: none;
    color: $color-blue-dark;
  }

  h2,
  h3 {
    margin-bottom: 0;
    font-weight: 600;
  }

  .modal-title {
    &.h4 {
      font-size: 14px;
    }
  }

  .close {
    &:focus {
      outline: none;
    }
    &:hover {
      color: $toryblue;
    }
  }

  button {
    border: none;
    background: transparent;
    font-size: 2rem;
    span {
      color: white;
      &:hover {
        color: $color-text;
      }
    }
  }
}
.modal-body {
  position: relative;
  padding: 1em 2em 2em;
  @include flex(1 1 auto);
  width: 100%;
}
.modal-footer {
  width: 100%;
}
.modal-content {
  border: none;
}
.backoffice-modal {
  border-radius: 20px;
  box-sizing: border-box;
  margin: 2em auto;

  .close {
    margin: 0;
  }

  .modal-content {
    position: relative;
    @include flexbox(100%, column, flex-start, flex-start);
    border-radius: 6px;
    height: 100%;
    pointer-events: auto;
    background-color: $color-white;
    background-clip: padding-box;
    outline: 0;
  }
}

.modal-90w {
  width: 90%;
  max-width: none !important;
}
.modal-70w {
  width: 70%;
  max-width: none !important;
}
.modal-50w {
  width: 50%;
  max-width: none !important;
}

.decline-incidence {
    display: flex;
    justify-content: center;
    align-items: center;

  .modal-content {
    width: 400px !important;
  }
}

.change-pass {
    display: flex;
    justify-content: center;
    align-items: center;

  .modal-content {
    width: auto !important;
  }
}

