@import '../../../scss/index.scss';

// .radio-buttons{
//     @include flexbox(auto, row, flex-start, center);
//     margin-bottom: 1rem;

//     &.isDisabled{
//         opacity: 1;
//         .radio-wrapper{
//             [type="radio"]:checked + label,
//             [type="radio"]:not(:checked) + label{
//                 cursor: default;
//             }
//             [type="radio"]:checked + label:after,
//             [type="radio"]:not(:checked) + label:after {
//                 background: $color-green-clear;
//             }
//         }
//     }
    
// }

.radio-buttons{
    @include flexbox(100%, row, flex-start, center, wrap);
    margin-bottom: 1rem;
    .radio-wrapper{
        margin-bottom: 1rem;
        margin-right: 0;
        padding-right: 1rem;
        box-sizing: border-box;
        width: 150px;
        max-width: 33.33%;

        [type="radio"]:checked + label:after{
            width: 10px;
            height: 10px;
        }
        [type="radio"]:not(:checked) + label:after {
            width: 10px;
            height: 10px;
            top: 4px;
            left: 4px;
        }

    }
}