@import "@backoffice/scss/index.scss";

.services-tenant {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;

    .return {
        @include return();
    }

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }

    .title-type{
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
        margin-left: 40px;
    }

    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .service-type-wrapper {
        @include flexbox(100%, row, start, start, wrap);
        // margin-left: 40px;
        margin-top: 40px;

        a {
            text-decoration: none;
            color: $color-text;
        }

        .service-type-item-wrapper {
            @include flexbox(100%, column, center, center, wrap);
            width: 200px;
            margin-bottom: 30px;
            cursor: pointer;

            .service-type-item {
                height: 130px;
                width: 130px;
                padding: 10px 20px;
                margin: 20px 40px;
                background-color: $color-white;
                border-radius: 10px;
                box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
                text-decoration: none;
                color: $color-text;
                background-color: #fbfbfb;

                .icon {
                    @include flexbox(100%, column, center, center, wrap);
                    color: $color-text;
                    height: 100%;

                    .material-icons {
                        font-size: 50px;
                    }
                }

                .type-name {
                    font-size: 14px;
                    color: $color-text;
                }

                &.featured {
                    background-color: $color-secondary;

                    .icon {
                        @include flexbox(100%, column, center, center, wrap);
                        color: white;
                        height: 100%;

                        .material-icons {
                            font-size: 50px;
                        }
                    }
                }
            }

            &:hover {
                .service-type-item {
                    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);

                    .icon {
                        color: black;
                    }

                    .type-name {
                        font-weight: bolder;
                        font-size: 24px;
                        color: #1f0f7b;
                    }
                }
            }
        }
    }

    .services-wrapper {
        @include flexbox(100%, row, flex-start, flex-start, wrap);

        .col-md-5-service-tenant {
            text-decoration: none;
        }

        .service-wrapper {
            @include flexbox(100%, row, center, center, wrap);
            position: relative;
            z-index: 1;
            width: 350px;
            height: 200px;
            margin: 20px 20px;

            @media screen and (max-width: 450px) {
                margin-inline: 0px;
            }

            
            &:hover {
                .service-img {
                    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
                    text-decoration: none;
                }
                .service-card {
                    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
                    text-decoration: none;}
    
            }

            .service-img {
                width: 100%;
                height: 120px;
                border-radius: 10px;
            }

            .service-card {
                @include flexbox(100%, column, center, center, wrap);
                position: relative;
                z-index: 10;
                top: -50px;
                width: 90%;
                height: 100px;
                padding: 10px 20px;
                margin: 10px;
                background-color: $color-white;
                border-radius: 10px;
                box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
                color: $color-text;
                background-color: #fbfbfb;

                // &:hover {
                //     box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
                //     text-decoration: none;
                // }

                .service-name {
                    font-size: 14px;
                    font-weight: bolder;
                    color: $color-text;
                    margin-bottom: 20px;

                }

                .service-address {
                    font-size: 14px;
                    margin-bottom: 10px;
                    color: #777777;
                }

                .service-phone {
                    color: $color-text;
                    font-size: 14px;
                }
            }
        }
    }
}