@import "@backoffice/scss/index.scss";
.tenants {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 25px;
    }

    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .filters-wrapper {
        @include flexbox(100%, row, flex-start, center);
        .tenant-search {
            padding-top: 22px
        }
    }

    .btn-grid-end {
        @include btn-grid-end();
    }
}


.disabled-icon{
    color: grey;
    cursor: default !important;

    &:hover{
        color: grey !important;
    }
}