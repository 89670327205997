@import "@backoffice/scss/index.scss";
.booking-tenant {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    height: calc(100vh - 60px);

    .title {
        @include title(14px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }

    .tab-button {
        @include tab-button();
        background-color: white;
        position: relative;
        z-index: 2;
        width: 220px;
        &:focus {
            outline: none;
        }
    }

    .tabs-wrapper {
        width: 100%;
        height: 100%;

        @media screen and (max-width: $media-medium) {
            padding: 0px;
        }

        .tabs{
            margin-bottom: 20px;
            padding-inline: 10px;
        }

        .tabs-underline{
            height: 2px;
            background-color: $color-white-clear;
            position: relative;
            z-index: 1;
            bottom: 2px;
        }
    }

    .empty-bookings {
        @include flexbox(100%, row, flex-start, flex-start, wrap);

        .empty-bookings-title {
            @include title(14px, $color-text, left, 0px, normal, normal);
            margin: 20px;
        }
    }

    .tabs_form{
        width: 100%;
        height: 100%;
        padding-bottom: 60px;
        overflow-y: scroll;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
    }

    .tabs_form::-webkit-scrollbar {
        display: none;
    }

    .card-list-wrapper {
        @include flexbox(100%, row, flex-start, center, wrap);
        padding: 10px;

        @media screen and (max-width: $media-medium) {
            @include flexbox(100%, row, flex-start, center, wrap);
        }
        .booking-tenant {
            width: calc(50% - 20px);
            height: 120px;
            padding: 0;
            margin: 10px 20px 10px 0px;
            @media screen and (max-width: $media-medium) {
                margin: 10px 0px 10px 0px;
                width: 100%;
            }
        }
    }

    .btn-end {
        @include btn-end();
        @include max-container-width();
        height: 10vh;
        background-color: white;
        border-top: 2px solid $color-white-clear;
    }
}
