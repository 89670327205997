@import "@backoffice/scss/index.scss";
.home {
    padding: 24px;
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }

    .subtitle {
        @include flexbox(100%, row, center, center, nowrap);
        @include title(12px, $color-text, left, 0px, normal, bolder);
        margin: 0;
        margin-bottom: 10px;
    }

    .filters-wrapper {
        @include flexbox(100%, row, flex-start, center);
        margin-bottom: 36px;
        padding: 0px;
        .col-xl-3 {
            padding: 0px;
            margin: 0px 10px 0px 0px;
        }
        .date-wrapper {
            @include date-wrapper();
            margin-bottom: 0px;
        }
    }

    .col-square-row {
        @include flexbox(100%, row, center, center, nowrap);

        @media screen and (max-width: $media-medium) {
            @include flexbox(100%, column, center, center, nowrap);
        }

        .col-square {
            flex: 0 0 calc(20% - 17px);
            max-width: calc(21% - 17px);
            margin: 0px 20px 0px 0px;
            @media screen and (max-width: $media-medium) {
                @include flexbox(100%, column, center, center, nowrap);
                max-width: calc(100% - 17px);
                margin: 0px;
            }
            .square-stats {
                @include flexbox(100%, row, center, center, nowrap);
                height: 133px;
                background: #fbfbfb;
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
                border-radius: 8px;
                cursor: pointer;
                font-size: 45px;
                font-weight: bolder;
                margin-bottom: 20px;

                &.active {
                    color: #fbfbfb;
                    background: #1f0f7b;
                    margin-bottom: 0px;
                    height: 153px;
                    border-radius: 8px 8px 0px 0px;
                    @media screen and (max-width: $media-medium) {
                        margin-bottom: 20px;
                        padding-bottom: 0px;
                        border-radius: 8px;
                        height: 133px;
                    }
                }

                &:hover:not(.active) {
                    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
                }
            }

            &:last-child {
                margin: 0px;
            }
        }
    }

    .stats-container {
        @include flexbox(100%, row, center, flex-start, nowrap);
        @media screen and (max-width: $media-medium-land) {
            @include flexbox(100%, column, center, center, wrap);
            height: inherit;
        }
        padding: 20px 0px 30px 0px;
        margin: 0px 0px;
        box-sizing: border-box;
        border-radius: 8px;
        border: 2px solid #f1f1f1;
        height: 650px;
        .legend-wrapper {
            @include flexbox(100%, row, flex-end, center, wrap);
            @media screen and (max-width: $media-small) {
                @include flexbox(100%, column, flex-start, flex-start, wrap);
            }
            .legend-square-wrapper {
                @include flexbox(33%, row, center, center);
                margin-right: 35px;
                .legend-square {
                    width: 13px;
                    height: 13px;
                    margin: 2px 10px 1px 0;
                    border-radius: 2px;
                }
                .legend-text {
                    width: calc(100% - 25px);
                    height: 16px;
                    margin: 0 0 0 0;
                    font-size: 13px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.33;
                    letter-spacing: normal;
                    color: $color-blue-dark;
                }
            }
        }

        .stats-container-bar {
            @include flexbox(calc(50% - 20px), column, center, center, nowrap);
            margin: 0px 10px;
            @media screen and (max-width: $media-medium) {
                @include flexbox(100%, column, center, center, nowrap);
                margin: 0px;
            }
        }
        .stats-container-circle {
            @include flexbox(calc(50% - 20px), column, center, center, nowrap);
            margin: 0px 10px;

            @media screen and (max-width: $media-medium) {
                @include flexbox(calc(100% - 20px), column, center, center, nowrap);
                margin: 0px;
            }
        }
        .stats-container-line {
            @include flexbox(calc(100% - 20px), column, center, center, nowrap);
            margin: 0px 10px;
            @media screen and (max-width: $media-medium) {
                @include flexbox(100%, column, center, center, nowrap);
                margin: 0px;
            }
            .e-chart {
                width: 100%;
            }

            .legend-wrapper {
                @include flexbox(100%, column, center, flex-end, wrap);
                @media screen and (max-width: $media-small) {
                    @include flexbox(100%, column, flex-start, flex-start, wrap);
                }
                .legend-square-wrapper {
                    @include flexbox(100px, row, center, center);
                    margin-right: 0px;
                }
            }
            &.news-graph {
                .legend-wrapper {
                    .legend-square-wrapper {
                        @include flexbox(150px, row, center, center);
                    }
                }
                .view-new {
                    height: 65px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    a {
                        color: $color-primary;
                        .material-icons-round {
                            cursor: pointer;
                            &:hover {
                                color: $color-primary-hover;
                            }
                        }
                    }
                }
            }
        }
    }
}

.e-custom-datepicker .e-calendar .e-content td.e-selected span.e-day,
.e-custom-datepicker .e-calendar .e-content td.e-today.e-selected span.e-day {
    background: $color-secondary !important;
    color: white !important;
}
.e-custom-datepicker .e-calendar .e-content td.e-today span.e-day {
    color: $color-secondary !important;
    border: none !important;
    box-shadow: none !important;
}
/* To change the Today button and date icon color*/

.e-custom-datepicker.e-date-wrapper span.e-input-group-icon.e-date-icon.e-icons.e-active,
.e-custom-datepicker .e-btn.e-flat.e-primary,
.e-custom-datepicker .e-css.e-btn.e-flat.e-primary {
    color: $color-secondary !important;
}
.e-custom-datepicker .e-btn.e-flat.e-primary::hover {
    background: rgb(19 112 219 / 17%);
    color: $color-secondary !important;
}

.e-accumulationchart {
    width: 100% !important;
    height: auto !important;
}
