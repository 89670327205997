@import "@backoffice/scss/index.scss";
.spacesdetail {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;
    .return {
        @include return();
    }

    .spacesdetail-form {
        .row {
            flex-wrap: wrap;
        }
        .weekdays-checbox-wrapper {
            @include flexbox(100%, row, flex-start, flex-start, wrap);
            margin-bottom: 20px;
            margin-left: 20px;
            .weekdays-check {
                @include flexbox(125px, column, flex-start, flex-start);
            }
        }
        .partialTime-check {
            @include flexbox(100%, column, flex-start, flex-start);
        }
    }

    .media-wrapper {
        @include flexbox(100%, row, flex-start, flex-start, wrap);
        padding: 10px 20px;
        .media-card {
            cursor: pointer;
            .img-wrapper {
                @include img-wrapper();
            }
            .media-body-wrapper {
                @include flexbox(175px, row, center, center, wrap);
                padding: 10px;
                padding-left: 20px;
                .media-name {
                    @include flexbox(calc(100% - 25px), column, center, center, wrap);
                    word-break: break-all;
                    font-size: 10px;
                }
                .material-icons-round {
                    @include flexbox(25px, column, flex-end, flex-end, wrap);
                    &:hover {
                        color: $color-primary-hover;
                    }
                }
            }
        }
    }

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        padding-left: 20px;
        margin-bottom: 5px;
    }

    .subtitle {
        @include title(12px, $color-text, left, 0px, normal, bolder);
        margin-left: 15px;
        margin-bottom: 20px;
        margin-top: 40px;
        border-bottom: 1px solid black;
    }

    .subtitle-media {
        @include title(12px, #595959, left, 0px, normal, 500);
        margin-left: 20px;
        margin-bottom: 10px;
        line-height: 15px;
    }

    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        padding-left: 20px;
        margin-bottom: 20px;
    }

    .upload-button {
        width: 100%;
    }

    .btn-end {
        @include btn-end();
    }
}
