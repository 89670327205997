@import "@backoffice/scss/index.scss";
.configuration-provider {
  @include max-container-width();
  @include general-padding-page();
  width: 100%;

  .title {
    @include title(18px, $color-text, left, 1px, normal, bolder);
    margin-bottom: 30px;
    padding-bottom: 10px;
    border-bottom: 1px solid $color-primary;
  }

  .content {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .subtitle {
    @include flexbox(100%, row, start, center, nowrap);
    @include title(12px, $color-text, left, 0px, normal, bolder);
    margin: 0;
    margin-bottom: 10px;
  }

  .divider {
    width: 100%;
    height: 1px;
    margin: 1rem 0;
    margin-bottom: 30px;
    overflow: hidden;
    border-top: 1px solid #b4b4b4;
  }

  .notifications {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    &-title {
      display: flex;
      flex-wrap: wrap;
      word-wrap: break-word;
      align-items: center;
      justify-content: start;
      min-width: 260px;

      @media screen and (max-width: 480px) {
        min-width: 202px;
      }
    }
    &-switches {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .custom-switch-control {
    margin-bottom: 10px;
    width: 80px;
    height: 40px;
  }

  .switch-icon {
    width: 40px;
    margin-bottom: 20px;
    padding-left: 5px;
    margin-right: 43px;

    .material-icons-round {
      font-size: 30px;
    }
  }
}

.active::before {
  background-color: $color-secondary !important;
}

.notActive::before {
  background-color: white !important;
}

.notActive::after {
  background-color: $color-secondary !important;
}

.disable::before {
  background-color: $color-shadow !important;
}
