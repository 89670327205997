@import "@backoffice/scss/index.scss";

.contact-popup {
	.title {
		@include title(18px, $color-text, left, 0px, normal, bolder);
		margin-bottom: 5px;
	}
	.end-button-wrapper {
		@include btn-end-popup();
		.add-contact-button {
			margin: 10px;
			max-width: 300px;
			width: 100%;
			@media screen and (max-width: $media-medium) {
				max-width: none;
			}
		}
	}
	.contact-form {
		margin: 10px;
		.row {
			flex-wrap: wrap;
		}
		.prefix {
			padding: 0;
			@media screen and (max-width: $media-medium) {
				padding: 0 15px;
			}
		}
		.weekdays-checbox-wrapper {
			@include flexbox(100%, row, flex-start, flex-start, wrap);
			margin: 20px;
			.weekdays-check {
				@include flexbox(125px, column, flex-start, flex-start);
			}
		}
		.partialTime-check {
			@include flexbox(100%, column, flex-start, flex-start);
			padding: 10px;
			padding-top: 27px;
		}
	}
	.btn-end {
		@include btn-end-popup();
		button {
			margin: 10px;
			max-width: 300px;
			width: 100%;
			@media screen and (max-width: $media-medium) {
				max-width: none;
			}
		}
	}
}
