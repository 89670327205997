@import "../../../scss/index.scss";

.switch {
    color: $color-text;
    font-weight: 600;
    font-size: 12px;
    height: 100%;
    width: 100%;
    
    .switch-wrapper {
        input,
        label {
            margin-top: 4px;
            cursor: pointer;
        }
        .custom-control-label::before {
            border: $color-text solid 1px;
            height: 22px;
            width: 45px;
            border-radius: 16px;
            left: -2.25rem;
        }
        .custom-control-label::after {
            top: calc(0.47rem);
            left: calc(-2rem);
            background-color: $color-primary;
            width: 16px;
            height: 16px;
            border-radius: 50%;
        }
        .custom-control-input:checked ~ .custom-control-label::before {
            color: #fff;
            border-color: $color-primary-hover;
            background-color: $color-primary-hover;
        }
        .custom-control-input:checked ~ .custom-control-label::after {
            left: -1.19rem;
        }
    }
}
