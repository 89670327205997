@import "../../scss/index.scss";

.fixed-label_container {
    position: fixed;
    z-index: 1089;
    width: 100%;
    top: 40px;
    padding-left: 280px;
    padding-right: 280px;
    @media screen and (max-width: $media-medium) {
        width: 100%;
        left: 0px;
        padding: 0px 20px;
    }
    .fixed-label {
        @include flexbox(100%, row, flex-start, center);
        height: 38px;
        padding: 0 9px;
        box-sizing: border-box;
        border-radius: 5px;
        span {
            padding-right: 0.5rem;
            font-size: 1.5rem;
            cursor: pointer;
        }
        p {
            line-height: 16px;
            margin-left: 5px;
            font-size: 12px;
        }
        &.success {
            background-color: #E8FAF4 !important;
            color: #459579;
        }
        &.error {
            background-color: #FAE9E8 !important;
            color: #D64343;
        }
        &.warning {
            background-color: $color-warning-bg !important;
            color: #916203;
        }
        &.information {
            background-color: $color-secondary !important;
            color: white;
        }
    }
}
