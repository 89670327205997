@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;800&display=swap');
/*PROJECT COLORS*/
:root {
    --color-primary: #000000;
    --color-primary-variant: #333333;
    --color-primary-variant2: #777777;
    --color-primary-variant3: #F1F1F1;
    --color-primary-disabled: #d8d8d8;
    --color-primary-pressed: #474e4c;
    --color-primary-hover: #434747;
    --color-secondary: #1F0F7B;
    --color-secondary-variant: #D64443;
    --color-secondary-variant2: 'background: linear-gradient(90deg, rgba(214,68,67,1) 0%, rgba(31,15,123,1) 100%)';
    --color-text: #000000;
    --color-error: #D64443;
    --color-error-bg: #fa8b8a;
    --color-warning: #d9d2aa;
    --color-warning-bg: #dad3a8;
    --color-success: #207E5D;
    --color-success-bg: #43D6A1;
    --color-menu-bg: #f1f1f1;
    --color-menu: #000;
    --color-header-bg: #ffffff;
    --color-header: #000;
    --color-main-bg: #ffffff;
}

$color-primary: var(--color-primary);
$color-primary-variant: var(--color-primary-variant);
$color-primary-variant2: var(--color-primary-variant2);
$color-primary-variant3: var(--color-primary-variant3);

$color-secondary: var(--color-secondary);
$color-secondary-variant: var(--color-secondary-variant);
$color-secondary-variant2: var(--color-secondary-variant2);

$color-primary-disabled: var(--color-primary-disabled);
$color-primary-pressed: var(--color-secondary);
$color-primary-hover: var(--color-primary-hover);

$color-secondary-disabled: "";
$color-secondary-pressed: "";
$color-secondary-hover: "";

$color-text: var(--color-text);
$color-error: var(--color-error);
$color-error-bg: var(--color-error-bg);
$color-warning: var(--color-warning);
$color-warning-bg: var(--color-warning-bg);
$color-success: var(--color-success);
$color-success-bg: var(--color-success-bg);

$color-menu-bg: var(--color-menu-bg);
$color-menu: var(--color-menu);
$color-header-bg: var(--color-header-bg);
$color-header: var(--color-header);
$color-main-bg: var(--color-main-bg);
/***/

/* OTHER COLORS */
$color-black: #000000;
$color-gray: #606060;
$color-gray-clear: #a2a2a2;
$color-disabled: #DFDFDE;
$color-disabled-clear: rgba(66, 66, 66, 0.2);
$color-disabled-dark: #424242;
$color-shadow: rgba(143, 143, 143, 0.5);
$color-white: #fff;
$color-white-clear: #f2f2f2;
$color-red: #f5454b;
$color-red-clear: #f76a6f;
$color-red-dark: #df2e34;
$color-border_red: #f84346;
$color-border_red-dark: #e12b2d;
$color-blue-dark-cold: #575f70;

$color-green: #508745;
$color-green-clear: #79b470;
$color-green-dark: #46763c;

$color-light-green: #aed1a9;
$color-light-green-clear: #deecdc;
$color-light-green-dark: #cde3ca;

$color-orange: #ffa400;
$color-orange-clear: #ffb640;
$color-orange-dark: #ff9500;
$color-bg-sand: #fff3de;

$color-blue-dark: #071e4f;
$color-blue-clear: #294785;
$toryblue: #1547bc;
$color-bg-sand-dark: #ffe0ae;

//Incident state colors
$color-state-pending: #FCF4CA;
$color-state-progress: #FCE5CA;
$color-state-resolved: #CAFCE4;

//Priority colors
$color-priority-high: #E64040; 
$color-priority-medium: #E09503;
$color-priority-low: #4EAB6E;

$box-shadow: 0 2px 4px 0 $color-shadow;
$border-default: 1px solid $color-green;

$media-xx-large: 1600px; //1400px
$media-large-land: 1400px; //1366px
$media-x-large: 1366px; //1200px
$media-medium-land: 1200px; //1024px 
$media-large: 1024px; //992px
$media-medium: 992px; //768px
$media-small-land: 768px; //640px
$media-small: 640px; //576px
$media-x-small: 576px; //450px
$media-xx-small: 400px; 

$font: 'Montserrat', sans-serif;

//Calendar EVENTS
$out-of-work: #a4a8f9;
$holidays: #ffad61;
$meeting: #eb93ac;

$font-size: 14;
$font-size-base: 1rem; 
$font-size-xs: $font-size-base * 0.675;
$font-size-sm: $font-size-base * 0.875;
$font-size-lg: $font-size-base * 1.25;
$font-size-h1: $font-size-base * 2.5; 
$font-size-h2: $font-size-base * 2; 
$font-size-h3: $font-size-base * 1.75; 
$font-size-h4: $font-size-base * 1.5; 
$font-size-h5: $font-size-base * 1.25; 
$font-size-h6: $font-size-base;

$sidebarWidth: 365;
