@import "@backoffice/scss/index.scss";

.tab-services {
    padding: 24px;
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
    }

    .btn-grid-end {
        @include btn-grid-end();
    }
}
