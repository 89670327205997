@import "@backoffice/scss/index.scss";
.uploader {
    @include flexbox(100%, column, flex-start, flex-start, wrap);
    .droparea {
        @include flexbox(100%, column, center, center, wrap);
        margin-top: 15px;
        position: relative;
        top: 0;
        min-height: 170px;
        background: #f1f1f1;
        border: 1px dashed $color-text;
        box-sizing: border-box;
        border-radius: 6px;
        padding: 5px;
        &.e-upload-drag-hover {
            border: 2px dashed $color-secondary;
            outline: none;
        }
        .drop {
            padding: 40px;
            .browse {
                font-family: $font;
                font-style: normal;
                font-weight: 500;
                line-height: 15px;
                text-align: center;
                color: $color-text;
                &:hover {
                    color: $color-primary-hover;
                }

                &:active {
                    color: $color-secondary;
                }
            }
        }
    }
    .e-upload {
        @include flexbox(100%, column, center, center, wrap);
        border: none;
        .e-file-select-wrap {
            display: none;
        }
        .e-upload-files {
            @include flexbox(100%, row, center, center, wrap);
            border: none;
            margin-top: 15px;
            .e-upload-file-list {
                border: none;
            }
        }
    }
    .file-wrapper {
        @include flexbox(100%, column, flex-start, flex-start, wrap);

        .img-wrapper {
            @include img-wrapper();
            max-width: none;
            background-color: white;
            margin: 0px 5px;
            width: 170px;
        }
        .body-wrapper {
            @include flexbox(160px, row, flex-start, center, wrap);
            margin: 0px 10px;
            .file-text {
                @include flexbox(110px, column, flex-start, flex-start, wrap);
                margin: 10px;
                .file-name {
                    margin: 5px 0px;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    width: 100%;
                    font-size: 12px;
                    max-width: 100%;
                }
                .file-size {
                    width: 100%;
                    font-size: 10px;
                }
            }
            .file-icons {
                @include flexbox(20px, column, flex-start, flex-start, wrap);
                .material-icons-round {
                    &:hover {
                        color: $color-primary-hover;
                    }
                }
            }
        }
    }
}
