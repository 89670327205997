@import "@backoffice/scss/index.scss";

.tab-general {
    .media-wrapper {
        overflow-y: auto;
        max-height: 430px;
    }
    .btn-end-multiple {
        margin-top: 25px;
        @include btn-end();
        @include flexbox(100%, row, space-between, center);
    }
}

.import{
    margin-block: 20px;
}
