@import "@backoffice/scss/index.scss";

.service-popup {
	.btn-end {
		@include btn-end-popup();
	}
	.media-wrapper {
		@include flexbox(100%, row, flex-start, flex-start, wrap);
		padding: 10px 20px;
		.media-card {
			cursor: pointer;
			.img-wrapper {
				@include img-wrapper();
			}
			.media-body-wrapper {
				@include flexbox(175px, row, space-between, center, wrap);
				padding: 10px;
				padding-left: 20px;
				.media-name {
					@include flexbox(100%, column, center, center, wrap);
					word-break: break-all;
					font-size: 10px;
				}
				.material-icons-round {
					@include flexbox(25px, column, flex-end, flex-end, wrap);
					&:hover {
						color: $color-primary-hover;
					}
				}
			}
		}
	}
}
