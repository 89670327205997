@import "@backoffice/scss/index.scss";

.incidents-tenant-create {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;

    .return {
        @include return();
    }

    .media-card {
        cursor: pointer;

        .img-wrapper {
            @include img-wrapper();
        }

        .media-body-wrapper {
            @include flexbox(175px, row, space-between, center, wrap);
            padding: 10px;
            padding-left: 20px;

            .media-name {
                @include flexbox(100%, column, center, center, wrap);
                word-break: break-all;
                font-size: 10px;
            }
        }
    }

    .incident-tenant-form {
        .title {
            @include title(18px, $color-text, left, 0px, normal, bolder);
            margin-bottom: 5px;
            margin-left: 20px;
        }

        .description {
            @include title(12px, $color-text, left, 0px, normal, normal);
            margin-bottom: 20px;
            margin-left: 20px;
        }

        .row {
            flex-wrap: wrap;
        }

        .uploader-wrapper {
            @include flexbox(100%, row, flex-start, center);
            padding: 0px;
        }

        .subtitle {
            @include title(12px, $color-text, left, 0px, normal, bolder);
            margin-left: 15px;
            margin-bottom: 20px;
            margin-top: 40px;
            border-bottom: 1px solid black;
        }

        .subtitle-media {
            @include title(12px, $color-text, left, 0px, normal, bolder);
            margin-left: 15px;
        }
    }

    .btn-end {
        @include btn-end();
        width: unset;
        position: relative;
        float: right;
        z-index: 0;
        height: 50px;
        button {
            margin: 10px;
            width: 100%;
            position: fixed;
            bottom: 0px;
        }
    }
}
