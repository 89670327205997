@import "@backoffice/scss/index.scss";

.home-provider {
    padding: 24px;
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 1px, normal, bolder);
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid $color-primary;
    }
}