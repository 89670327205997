@import "@backoffice/scss/index.scss";
.tab-historic {
    padding: 0px 24px;
    width: 100%;
    background-color: white;
    @media screen and (max-width: $media-medium) {
        padding: 24px 0px;
    }
    .end-button-wrapper {
        @include btn-end();
        .add-administrator-button {
            margin: 10px;
            max-width: 300px;
            width: 100%;
            @media screen and (max-width: $media-medium) {
                max-width: none;
            }
        }
    }
    .incident-provider-historic {
        padding: 40px;
        div:first-child .row .content .left
        {
            color: $color-secondary;
            font-size: $font-size-lg;
            font-weight: bold;
            &:after {
                width: 18px;
                height: 18px;
                right: -9px;
                background-color: $color-secondary;
                top: calc(50% - 12px);
            }
        }
        div:first-child .row .content
        {
            &:before {
                border-color: $color-secondary;
            }
        }
        div:last-child .row .content
        {
            &:before {
                content: none;
            }
        }


        .row {
            position: relative;
            .content
            {
                @include flexbox(100%,row, flex-start, normal, nowrap, flex-start);
                &:before {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 100%;
                    top: 50%;
                    left: 50%;
                    margin-left: -1px;
                    border-right: 2px dashed $color-black;
                }
                .left, .right {
                    padding: 20px 60px;
                    @include title($font-size-lg, $color-text, right, normal, normal, normal);

                    @media screen and (max-width: $media-medium) {
                        padding: 20px;
                    }
                }
                .right {
                    position: relative;
                    text-align: left;
        
                    .history-incident-status {
                        .status {
                            @include flexbox(152px, row, center, center, wrap);
                            border-radius: 0px 10px;
                            height: 30px;
                            font-size: 12px;
                            color: $color-text;
                            &.progress {
                                background-color: $color-state-progress;
                            }
                            &.pending {
                                background-color: $color-state-pending;
                            }
                            &.resolved {
                                background-color: $color-state-resolved;
                            }
                        }
                    }
                }
                .left {
                    position: relative;
                    text-align: right;
                    .history-incident-date {
                        line-height: 30px;
                    }
                    &:after {
                        position: absolute;
                        content: '';
                        background-color: $color-black;
                        border-radius: 100%;
                        width: 12px;
                        height: 12px;
                        top: calc(50% - 6px);
                        right: -6px;
                    }
                }
    
            }
        }
    }
}