@import "@backoffice/scss/index.scss";

.btn-base {
    @include btn($fontWeight: bold);

    .button-wrapper {
        @include flexbox(100%, row, center, center, nowrap);
        // min-width: 150px;
        display: inline-flex;
        .icon {
            position: relative;
            left: -15px;
        }
    }

    &_outline {
        @extend .btn-base;
        background-color: white;
        color: $color-primary;
        border: 1px solid $color-primary-variant2;
        &:hover {
            border: 1px solid $color-primary-hover;
            color: $color-primary-hover;
        }
        &:focus {
            border: 1px solid $color-primary-hover;
            color: $color-primary-hover;
            background-color: $color-white;
        }
        &:active {
            border: 1px solid $color-primary-hover;
            color: $color-primary-hover;
            background-color: white;
        }
        &:disabled {
            cursor: not-allowed;
            border-color: $color-primary-disabled;
            color: $color-white;
        }
    }
    &_outline-clear {
        @extend .btn-base_outline;
        border-width: 0;
        background-color: white;
        &:hover {
            border-width: 0;
            color: $color-gray;
        }
        &:disabled {
            border-width: 0;
            color: $color-disabled;
            cursor: not-allowed;
        }
    }
    &_normal {
        @extend .btn-base;
        &:hover {
            background-color: $color-primary-hover;
        }
        &:focus {
            background-color: $color-primary-pressed;
        }
        &:disabled {
            background: $color-primary-disabled;
            cursor: not-allowed;
            border: transparent;
            color: $color-white;
        }
    }
    &_outline-normal {
        @extend .btn-base_normal;
        background-color: transparent;
        $color: $color-primary;
        border: 1px solid #cddeff;
        &:hover {
            $color: $color-primary-hover;
            background-color: #676fa3;
        }
        &:focus {
            $color: $color-primary-pressed;
            background-color: $color-white;
        }
        &:active {
            $color: $color-primary;
            background-color: transparent;
        }
        &:disabled {
            cursor: not-allowed;
            border-color: $color-primary-disabled;
            color: $color-white;
        }
    }
    &_primary {
        @extend .btn-base_normal;
        background-color: $color-orange-clear;
        &:hover {
            background-color: $color-orange-dark;
        }
        &:focus {
            background-color: $color-orange-dark;
        }
        &:disabled {
            background: $color-disabled;
            cursor: not-allowed;
            color: $color-white;
        }
    }
    &_outline-primary {
        @extend .btn-base_primary;
        background-color: $color-white;
        border: 1px solid $color-orange-clear;
        &:hover {
            background-color: $color-orange-dark;
        }
        &:focus {
            background-color: $color-orange-dark;
        }
        &:disabled {
            background: $color-disabled;
            cursor: not-allowed;
            color: $color-white;
        }
    }
    &_secondary {
        @extend .btn-base;
        background-color: $color-disabled;
        color: $color-white;
        &:hover {
            background-color: $color-disabled-dark;
        }
        &:disabled {
            background: $color-disabled;
            cursor: not-allowed;
            border: transparent;
            color: #ffffff;
        }
    }
    &_outline-secondary {
        @extend .btn-base_secondary;
        color: $color-disabled;
        background-color: $color-white;
        border: 1px solid $color-disabled;

        &:hover {
            color: $color-white;
            background-color: $color-disabled-dark;
            border: 1px solid $color-disabled;
        }
        &:disabled {
            background: $color-disabled;
            border: 1px solid $color-disabled;
            cursor: not-allowed;
            color: $color-disabled;
        }
    }
    &_danger {
        @include btn($color: $color-white-clear, $bgColor: $color-error, $fontWeight: bold);
        &:hover {
            background-color: $color-red;
        }
        &:focus {
            background-color: $color-red;
        }
        &:active {
            background-color: $color-red;
        }
        &:disabled {
            background: $color-disabled;
            border: 1px solid $color-disabled;
            cursor: not-allowed;
            color: $color-white;
        }
    }
    &_outline-danger {
        @extend .btn-base_danger;
        color: $color-error;
        border: 1px solid $color-error;
        background-color: $color-white;
        &:hover {
            color: $color-white;
            border-color: $color-white;
            background-color: $color-red-clear;
        }
        &:focus {
            color: $color-red-clear;
            border-color: $color-red-clear;
            background-color: $color-white;
        }
        &:active {
            color: $color-red-clear;
            border-color: $color-red-clear;
            background-color: $color-white;
        }
    }

    &_tab-btn{
        @extend .btn-base;
        &:focus {
            background-color: $color-secondary;
        }
        &-active{
            background-color: $color-secondary;
        }
    }
}
//Sidebar filters
.btn-container {
    @include flexbox(100%, row, center, center);
    margin-bottom: 10px;
    position: relative;

    & > button {
        width: 100%;
        border-width: 0;
    }
    .material-icons {
        @media only screen and (min-width: $media-x-small) {
            position: absolute;
            left: 39%;
            top: 7px;
        }

        @media only screen and (min-width: $media-medium) {
            position: absolute;
            left: 39%;
            top: 7px;
        }

        @media only screen and (min-width: $media-large) {
            position: absolute;
            left: 39%;
            top: 5px;
        }

        @media only screen and (min-width: $media-medium-land) {
            position: absolute;
            left: 60px;
        }
    }
}
.btn-group-row {
    button {
        margin-right: 1em;
        &:last-of-type {
            margin-right: 0;
        }
    }
}

//New btn styles

.btn-base {
    &.outline-normal {
        @extend .btn-base_normal;
        background-color: transparent;
        color: $color-green;
        border: 1.5px solid $color-green-dark;
        height: 32px;
        &:hover {
            color: $color-green-dark;
            background-color: $color-white;
        }
        &:focus {
            color: $color-green-dark;
            background-color: $color-white;
        }
        &:active {
            color: $color-green-dark;
            background-color: transparent;
        }
        &:disabled {
            cursor: not-allowed;
            border-color: $color-disabled;
            color: $color-white;
        }
    }
}
