@import "@backoffice/scss/index.scss";

.login-form {
    width: 100%;
    max-width: 400px;
    padding: 0px 40px;

    .login-title {
        font-weight: bolder;
        margin-bottom: 15px;
        font-size: 14px;
    }

    .form-wrapper {
        margin-bottom: 10px;
    }

    .link-forgotPass {
        text-align: center;
        margin-bottom: 2rem;
        text-decoration: underline;
        font-weight: bolder;
        cursor: pointer;
        font-size: 12px;

        &:hover {
            color: $color-primary-hover;
        }

        &:active {
            color: $color-secondary;
        }
    }

    .pointer {
        cursor: pointer;
    }

    .button-container {
        button {
            width: 100%;
            margin-bottom: 0.5em;
        }
    }

    .input-container .inputLabel {
        padding-left: 0;
    }
}
