@import "@backoffice/scss/index.scss";

.incidents-provider {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 20px;
    }

    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .e-radio {
        @include radioButton(#fff, #777, #262626, 20px);
    }

    .row {
        flex-wrap: wrap;
    }

    .title-status {
        @include title($font-size-lg, $color-text, left, normal, normal, bold);
        margin: 0;
        padding: 20px;
    }

    .pending-list,
    .inprogress-list,
    .resolved-list {
        min-height: 40vh;
        min-width: 33%;
        padding: 0px;
        @media screen and (max-width: $media-x-small) {
            min-width: 100%;
        }
        @media screen and (max-width: $media-x-large) {
            min-height: unset;
        }
    }

    .border-list {
        border-right: 2px dashed $color-black;
        border-left: 2px dashed $color-black;

        @media screen and (max-width: $media-x-large) {
            border-right: none;
            border-left: none;
        }
    }

    .gridTable{
        cursor: pointer;
    }

    .card-list-wrapper {
        @include flexbox(100%, column, flex-start, center, nowrap, center);
        padding: 0px 10px 0px 10px;
        @media screen and (max-width: $media-medium) {
            @include flexbox(100%, row, center, center, wrap);
            padding: 0px;
        }

        .card {
            @include flexbox(100%, row, space-around, flex-start, nowrap, center);
            background-color: $color-white;
            height: 130px;
            border-radius: 10px;
            box-shadow: 0 2px 4px 0 rgba(143, 143, 143, 0.5);
            margin: 0;
            margin-bottom: 20px;
            text-decoration: none;
            color: $color-text;

            @media screen and (max-width: $media-medium) {
                @include flexbox(100%, row, flex-start, flex-start, wrap);
                margin: 0 0 20px 0;
            }

            @media screen and (max-width: $media-x-small) {
                min-width: 100%;
            }

            &:hover {
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
            }

            .content-card-right {
                @include flexbox(130px, column, flex-start, flex-start, nowrap, center);
                height: 100%;

                .status-wrapper {
                    @include flexbox(100%, column, flex-start, center, nowrap, center);
                    height: 100%;
                    position: relative;
                    .status {
                        @include flexbox(100%, row, center, center, wrap);
                        border-radius: 0px 10px;
                        height: 30px;
                        font-size: 12px;
                        color: $color-text;

                        &.progress {
                            background-color: $color-state-progress;
                        }

                        &.pending {
                            background-color: $color-state-pending;
                        }
                        &.resolved {
                            background-color: $color-state-resolved;
                        }
                    }
                    .subStatus {
                        color: $color-secondary;
                        padding: 10px 0;
                        text-align: center;
                        font-size: 12px;
                        font-weight: bolder;
                    }
                    p.priority-incident-card {
                        @include flexbox(100%, row, center, center, nowrap, center);
                        font-size: 10px;

                        &-deadline{
                            font-size: 10px;
                            margin-top: 6px;
                        }
                    }
                    span.material-icons-round.Normal {
                        color: $color-priority-medium;
                        margin-right: 3px;
                    }
                    span.material-icons-round.Alta {
                        color: $color-priority-high;
                        margin-right: 3px;
                    }
                    span.material-icons-round.Baja {
                        color: $color-priority-low;
                        margin-right: 3px;
                    }
                }
            }

            .card-wrapper {
                @include flexbox(calc(100% - 130px), row, flex-start, center, wrap);
                padding: 20px 0px 20px 20px;

                &.incident {
                    padding: 15px 0px 15px 20px;
                    height: 100%;
                }

                .body-wrapper {
                    @include flexbox(100%, column, flex-start, flex-start, nowrap);
                    height: 85%;

                    @media screen and (max-width: 450px) {
                        padding: 0px;
                    }

                    .title-card {
                        @include title(12px, $color-text, left, 0px, normal, 900);
                        width: 100%;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }

                    .incident-type-card {
                        margin-top: 5px;
                    }

                    .incident-type-card,
                    .building-incident-card {
                        @include title(10px, $color-text, left, 0px, normal, normal);
                    }

                    .people-card {
                        @include title(12px, $color-text, left, 0px, normal, 400);
                    }

                    .description-card {
                        font-size: 10px;
                        line-height: 12px;
                        max-height: 44px;
                        word-break: keep-all;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        color: $color-text;
                    }
                }

                .footer-wrapper {
                    @include flexbox(100%, row, space-between, center, nowrap);
                    .date-card {
                        @include title(10px, #777777, left, 0px, normal, normal);
                    }
                }

                .arrow-wrapper {
                    @include flexbox(30px, column, center, center, wrap);
                    height: 100%;

                    .material-icons-round {
                        font-size: 30px;
                    }
                }
            }
        }
    }

    .e-grid {
        .e-rowcell {
            .statusTemplate {
                padding: 5px 10px;
                border-radius: 8px;
                text-align: center;
            }
        }
    }

    .header-container {
        @include flexbox(100%, row, space-between, flex-end, wrap, center);
        .col-lg-11,
        .col-xl-4,
        .col-xl-3 {
            padding: 0px 20px 0px 0px;
        }
        .filters-wrapper {
            @include flexbox(100%, column, space-around, flex-start, nowrap, flex-start);
            .content-filters {
                @include flexbox(100%, row, flex-start, center, wrap, center);
            }
        }
        .view-mode-row {
            @include flexbox(100%, row, flex-end, center, wrap, center);
            .view-text {
                font-size: 12px;
                margin-right: 10px;
            }
            .icons {
                text-align: center;
                .active {
                    border-bottom: 3px solid $color-secondary;
                    color: $color-secondary;
                }
                span {
                    cursor: pointer;
                    padding: 0px 5px;
                    font-size: 30px;
                }
                span:hover {
                    color: $color-primary-hover;
                    border-color: $color-primary-hover;
                }
                span:focus,
                span:active {
                    color: $color-secondary;
                    border-color: $color-secondary;
                }
            }
        }
    }
}
