@import "../../scss/index.scss";
.file-preview {
    @include flexbox(100%, row, center, center, wrap);
    margin-bottom: 10px;
    height: 100%;
    .video-react{
        padding-top: 0 !important;
        cursor: pointer;
        min-height: 350px;
        .video-react-video{
            width: 100% !important;
        }
        .video-react-button,
        .video-react-control-bar{
            display: none !important;
        }
    }
}
