@import "@backoffice/scss/index.scss";
.image-header {
    @include flexbox(100%, row, flex-start, center, wrap);
    .col-lg-12 {
        padding: 0;
    }
    .img-home-wrapper {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 200px;
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
}

.layout {
    background-color: $color-main-bg;
}

.home-tenant {
    @include max-container-width();
    padding: 20px 50px;
    width: 100%;
    background-color: $color-main-bg;

    @media screen and (max-width: $media-medium) {
        padding: 0px 20px;
        height: 100%;
    }

    .row {
        flex-wrap: wrap;
    }

    .header-wrapper {
        @include flexbox(100%, row, space-between, center);
        margin-bottom: 20px;

        .subtitle {
            @include title(14px, $color-text, left, 0px, normal, bolder);
            margin: 0;
        }

        font-size: 14px;
        color: $color-text;
        .view-all {
            font-size: 14px;
            color: $color-text;
            text-decoration: underline;
            &:hover {
                color: $color-primary-hover;
            }
            &:focus,
            &:active {
                color: $color-secondary;
            }
        }
        &.incidents {
            margin-top: 20px;
        }
    }

    .button-home {
        min-width: 220px;
    }

    .col-lg-6 {
        padding: 10px 25px;
        @media screen and (max-width: $media-medium) {
            padding: 10px;
        }
    }

    .col-lg-12 {
        padding: 0;
    }

    .title {
        @include title(18px, $color-text, left, 1px, normal, bolder);
        margin-bottom: 20px;
    }

    .create-button {
        width: 100%;
    }

    .col-lg-3 {
        padding: 0;
    }
}
