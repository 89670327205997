@import "@backoffice/scss/index.scss";

.contacts-tenant {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 30px;
    }
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .contacts-wrapper {
        @include flexbox(100%, row, flex-start, center, wrap);

        .contact-tenant {
            @include flexbox(calc(50% - 20px), row, space-between, center, wrap);
            padding: 10px 20px;
            cursor: pointer;
            background-color: $color-white;
            height: 120px;
            border-radius: 10px;
            box-shadow: 0 3px 10px 0 rgb(0 0 0 / 10%);
            text-decoration: none;
            color: $color-text;
            margin: 10px 20px 10px 0px;
            @media screen and (max-width: $media-medium) {
                width: 100%;
                margin: 10px 0px 10px 0px;
            }

            .body-wrapper {
                @include flexbox(70%, column, center, center, nowrap, center);

                .contact-position {
                    width: 100%;
                    margin: 2px 0px;
                    font-size: 14px;
                    font-weight: bolder;
                }

                .contact-name {
                    margin: 5px 0px;
                    width: 100%;
                }

                .contact-phone,
                .contact-email {
                    margin: 5px 0px;
                    width: 100%;
                }
            }

            &:hover {
                box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
            }

            .arrow-wrapper {
                @include flexbox(30px, column, center, center, wrap);
                height: 100%;
                .material-icons-round {
                    font-size: 30px;
                }
            }
        }
    }
}
