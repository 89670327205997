@import "@backoffice/scss/index.scss";

.services-tenant-detail {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;

    .return {
        @include return();
        padding: 30px;
    }

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 5px;
        padding-left: 30px;
    }
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .service-detail-wrapper {
        @include flexbox(100%, row, start, center, wrap);

        padding-left: 30px;
        margin-left: auto;
        margin-right: auto;

        .img-service-wrapper {
            margin-top: 10px;
            margin-right: 20px;
            padding: 0px;
            height: 300px;
            img {
                border-radius: 8px;
                max-width: 400px;
                width: 100%;
                object-fit: scale-down;
            }
        }

        .service-detail-body {
            .service-name {
                font-size: 14px;
                font-weight: bolder;
                color: $color-text;
                margin-bottom: 20px;
                margin-top: 20px;
            }

            .service-address,
            .service-email,
            .service-phone,
            .service-url {
                @include flexbox(100%, row, space-between, center, wrap);
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 15px;
                color: $color-text;
                margin-top: 10px;
                a {
                    color: $color-text !important;
                    &:hover {
                        color: $color-primary-hover !important;
                    }
                    &:active {
                        color: $color-secondary;
                    }
                }
            }

            .icon {
                margin-left: 10px;
            }

            .description-title {
                font-weight: bolder;
                margin-top: 25px;
                margin-bottom: 10px;
            }
        }
    }
}
