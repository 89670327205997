@import "../../../scss/index.scss";

.input-container {
    @include flexbox(auto, column, flex-start, flex-start);
    position: relative;
    padding-bottom: 1.25em;

    input {
        font-family: $font !important;
        font-size: 12px !important;
    }

    .pointer {
        cursor: pointer;
    }

    .inputLabel {
        margin: 0px 6px 5px 0;
        padding-left: 0;
        color: $color-text;
        font-weight: 600;
        font-size: 12px;
    }

    .material-icons {
        position: absolute;
        bottom: 1.55rem;
        color: $color-primary;
        font-size: 1.6rem;
        &.left {
            left: 0.5rem;
        }
        &.right {
            right: 0.5rem;
        }
        &:hover {
            color: $color-primary-hover;
        }
    }

    &:focus {
        .material-icons {
            color: $color-secondary;
        }
    }
    &:focus-visible {
        .material-icons {
            color: $color-secondary;
        }
    }
    .input-control {
        width: 100%;
        height: 32px;
        border-radius: 16px;
        border: solid 1px $color-primary-variant2;
        padding: 0.25em 3em 0.25em 0.6em;
        box-sizing: border-box;
        font-size: 14px;
        color: $color-text;
        background-color: $color-white !important;

        &:read-only {
            background-color: rgb(242, 242, 242) !important;
        }

        &.left {
            padding-left: 2.25rem;
        }

        &:hover {
            border: 2px solid $color-primary-hover;
        }

        &:focus {
            border: 2px solid $color-secondary;
        }

        &:focus-visible {
            border: 2px solid $color-secondary;
        }

        &.input-error {
            border: 1px solid $color-error;
        }

        &.input-outline {
            border: 1px solid $color-primary-variant2;

            &:hover {
                border: 2px solid $color-primary-hover;
            }

            &:focus {
                border-width: 2px;
                border-color: $color-secondary;
            }

            &:focus-visible {
                border: 2px solid $color-secondary;
            }

            &.input-success {
                border-color: $color-green-dark;
            }

            &.input-error {
                border: 1px solid $color-error;
            }
        }
    }

    .error {
        color: $color-error;
        position: absolute;
        font-weight: normal;
        bottom: 0.15em;
        font-size: 12px;
        left: 10px;
    }

    .valid {
        color: $color-green-clear;
        position: absolute;
        font-weight: bold;
        bottom: 10px;
        left: 10px;
        font-size: 14px;
    }

    .e-time-wrapper {
        width: 100% !important;
        height: 32px !important;
        border-radius: 45px !important;
        border: none !important;
        padding: 0 1em !important;
        box-sizing: border-box !important;
        border: 1px solid $color-primary-variant2 !important;

        &:focus {
            border: 2px solid $color-secondary !important;
            .e-time-icon {
                color: $color-secondary !important;
            }
        }
        &.e-input-focus {
            border: 2px solid $color-secondary !important;
        }

        &.input-error {
            border-color: $color-secondary !important;
        }

        .e-time-icon {
            color: $color-text !important;
        }
    }
}

.e-input-focus {
    &::after {
        background: transparent !important;
    }
    &::before {
        background: transparent !important;
    }
}

.e-ul {
    .e-list-item {
        &.e-hover {
            color: black !important;
        }
        &.e-active {
            color: $color-secondary !important;
        }
        &:hover {
            background-color: $color-secondary !important;
            color: white !important;
        }
    }
}

.e-input::selection {
    color: black !important;
    background-color: transparent !important;
}

input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
    border-radius: 4px;
    margin-right: -0.5rem;
    font-weight: bold;
    font-size: 18px;
    filter: invert(38%) sepia(22%) saturate(952%) hue-rotate(64deg) brightness(100%) contrast(89%);
}

input[type="time"]::-webkit-calendar-picker-indicator:hover {
    opacity: 1;
}

::-webkit-input-placeholder {
    color: $color-gray-clear !important;
    font-size: 14px;
}

:-ms-input-placeholder {
    color: $color-gray-clear !important;
    font-size: 14px;
}

::placeholder {
    color: $color-gray-clear !important;
    font-size: 14px;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.e-input-group.e-control-wrapper.e-time-wrapper::after,
.e-input-group.e-control-wrapper.e-time-wrapper::before {
    background: transparent !important;
}

input:-internal-autofill-selected {
    background-color: none;
}
