@import "@backoffice/scss/index.scss";

.modal-calendar {
    @include flexbox(100%, row, flex-end, flex-start);

    .date-wrapper {
        @include date-wrapper();
        margin-bottom: 1.5rem;
        & > label {
            margin-bottom: 0.5rem;
            color: $color-text;
            font-weight: bolder;
            font-size: 0.75rem;
        }
    }
    .date-selected {
        @include title(14px, $color-text, left, 1px, normal, bolder);
        padding-left: 15px;
        margin-bottom: 20px;
    }

    .hours-wrapper {
        font-family: $font !important;
        .input-container {
            .inputLabel {
                margin-top: 0.2rem;
            }
            input[type="time"] {
                margin-top: 0;
            }
        }
    }
    .action-wrapper {
        @include flexbox(100%, row, flex-end, flex-start);
        .button-container {
            width: 150px;
            @media screen and (min-width: $media-medium) {
                width: auto;
            }
        }
        button:disabled {
            cursor: default;
            background-color: $color-gray-clear;
            color: $color-gray;
        }
    }
    .error {
        color: $color-red;
        font-weight: bold;
    }
    .redirect-edit-caravan {
        display: flex;
        align-items: center;
        .license {
            padding: 10px;
            min-width: 150px;
            text-align: center;
            border-radius: 20px;
            display: inline-block;
            background-color: $color-disabled;
            color: $color-blue-dark;
        }
        .edit-container {
            @include flexbox(100%, row, flex-end, center);
            span.btn-base_primary {
                display: flex;
                align-items: center;
            }
        }
    }
    .btn-end {
        @include btn-end-popup();
        button {
            margin: 20px;
            @media screen and (max-width: $media-medium) {
                margin: 10px 20px;
            }
        }
    }
}
