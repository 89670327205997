@import "@backoffice/scss/index.scss";
.incidents-tenant {
    @include max-container-width();
    @include general-padding-page();
    width: 100%;

    .title {
        @include title(18px, $color-text, left, 0px, normal, bolder);
        margin-bottom: 30px;
    }
    .description {
        @include title(12px, $color-text, left, 0px, normal, normal);
        margin-bottom: 20px;
    }

    .btn-end {
        @include btn-end();
    }
    .filters-wrapper {
        @include flexbox(100%, row, flex-start, center);
        padding: 0px;
        margin-bottom: 10px;

        .col-xl-3 {
            padding: 0px;
            margin: 0px 10px 0px 0px;
        }
        .date-wrapper {
            @include date-wrapper();
            margin-bottom: 0px;
        }
    }
    .card-list-wrapper {
        @include flexbox(100%, row, flex-start, center, wrap);
        padding: 0px;
        @media screen and (max-width: $media-medium) {
            @include flexbox(100%, row, flex-start, center, wrap);
            padding: 0px;
        }
        .incidents-tenant {
            width: calc(50% - 20px);
            padding: 0;
            margin: 10px 20px 10px 0px;
            @media screen and (max-width: $media-medium) {
                margin: 10px 0px 10px 0px;
                width: 100%;
            }
        }
    }
}


/* To change the selected day's and today's color*/  
.e-custom-datepicker .e-calendar .e-content td.e-selected span.e-day, 
.e-custom-datepicker .e-calendar .e-content td.e-today.e-selected span.e-day { 
  background: $color-secondary !important; 
  color: white !important; 
} 
.e-custom-datepicker .e-calendar .e-content td.e-today span.e-day { 
  color: $color-secondary !important; 
  border: none !important; 
  box-shadow: none !important; 
} 
/* To change the Today button and date icon color*/ 
 
.e-custom-datepicker.e-date-wrapper 
  span.e-input-group-icon.e-date-icon.e-icons.e-active, 
.e-custom-datepicker .e-btn.e-flat.e-primary, 
.e-custom-datepicker .e-css.e-btn.e-flat.e-primary { 
  color: $color-secondary !important; 
} 
.e-custom-datepicker .e-btn.e-flat.e-primary::hover { 
  background: rgb(19 112 219 / 17%); 
  color: $color-secondary !important; 
} 