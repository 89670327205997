@import "../../../scss/index.scss";

.combo-wrapper {
    width: auto;
    font-family: $font;
    margin-bottom: 1em;
    label {
        margin: 0 6px 5px 0;
        padding-left: 0;
        color: $color-text;
        font-weight: 600;
        font-size: 12px;
        display: inline-block;
    }
    //Combobox
    .comboBox {
        width: auto;
        [class*="-control"] {
            min-height: unset;
            //height: 32px;
            min-height: 32px;
            min-width: 200px;
            font-size: 12px;
            height: auto;
            border-radius: 16px;
            border-color: $color-primary-variant2;
            box-shadow: none;
            cursor: pointer;
            font-family: $font;

            &:hover {
                border: 2px solid $color-primary-hover;
            }
            
            &:focus {
                border: 2px solid $color-secondary;
            }

            & > [class*="-ValueContainer"] {
                padding: 0px 10px;
                font-size: 12px;
            }

            & > [class*="css-"] {
                &:last-of-type {
                    height: 32px;
                    width: 33px;
                    position: absolute;
                    top: -1px;
                    right: -1px;
                    [class*="-indicatorSeparator"] {
                        display: none;
                    }
                    [class*="-indicatorContainer"] {
                        padding: 0;
                        left: 4px;
                        position: absolute;
                        color: $color-primary;
                        &:nth-child(1) {
                            display: none;
                            left: -12px;
                        }
                    }
                }
            }

            //pills
            [class*="-singleValue"] {
                font-size: 12px;
                max-width: calc(100% - 28px);
            }
            [class*="-multiValue"] {
                background-color: $color-secondary !important;
                font-size: 12px;
                border-radius: 10px;
                color: white;
                border: 1px solid $color-primary;
                & > div:first-of-type {
                    color: white;
                    font-weight: bold;
                }
                & > div:last-of-type {
                    &:hover {
                        color: $color-primary-hover;
                        background-color: transparent;
                    }
                }
            }
        }
        [class*="-menu"] {
            border-radius: 0;
            margin-top: 4px;
            [class*="-MenuList"] {
                overflow-x: hidden;
                padding: 0;
                white-space: nowrap;
                & [class$="-option"] {
                    border-bottom: 1px solid $color-primary;
                    background-color: $color-white;
                    height: 40px;
                    line-height: 25px;
                    color: $color-blue-dark;
                    font-size: 12px;
                }
                & [class$="-option"]:hover {
                    cursor: pointer;
                    background-color: $color-primary;
                    color: white;
                }
                & [class$="-option"]:focus {
                    cursor: pointer;
                    color: white;
                    background-color: $color-primary;
                }
            }
        }
    }

    input {
        font-size: 14px;
        font-family: $font;
    }

    i {
        color: $color-white;
        background: $color-secondary;
        padding: 7px;
        height: 16px;
        top: -1px;
        right: -1px;
    }

    &.isFocus {
        .comboBox {
            [class*="-control"] {
                border-width: 2px;
                border-color: $color-primary-hover;
            }
            & [class$="-menu"] {
                border-radius: 0;
                margin-top: 4px;
                & [class$="-MenuList"] {
                    overflow-x: hidden;
                    padding: 0;
                    white-space: nowrap;
                    & [class$="-option"] {
                        border-bottom: 1px solid $color-primary;
                        background-color: $color-white;
                        height: 40px;
                        line-height: 25px;
                        color: $color-text;
                        font-size: 12px;
                        &:first-of-type {
                            background-color: $color-white;
                        }
                        &:last-of-type {
                            background-color: $color-white;
                        }
                    }

                    & [class$="-option"]:hover {
                        cursor: pointer;
                        background-color: $color-primary-hover;
                        color: white;
                    }
                    & [class$="-option"]:focus {
                        cursor: pointer;
                        background-color: $color-primary-hover;
                        color: white;
                    }
                }
            }
        }
    }

    &.isDisabled {
        [class^="ms-Label"] + div.comboBox [class*="-control"] {
            border-color: transparent;
            [class*="-singleValue"] {
                border-color: $color-gray-clear;
                background-color: $color-white-clear !important;
                color: $color-gray-clear;
                font-size: 12px;
            }

            & > [class*="css-"]:last-of-type [class*="-indicatorContainer"] {
                color: $color-gray-clear;
            }
        }
    }
}
