@import "@backoffice/scss/index.scss";

.profile-tenant {
  @include max-container-width();
  @include general-padding-page();
  width: 100%;

  .title {
    @include title(18px, $color-text, left, 0px, normal, bolder);
    margin-bottom: 25px;
  }

  .description {
    @include title(12px, $color-text, left, 0px, normal, normal);
    margin-bottom: 20px;
  }

  .fixed-label-profile {
    @include flexbox(100%, row, flex-start, center);
    height: 38px;
    padding: 0 9px;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 20px;
    background-color: #fae9e8;
    color: $color-error;
    span {
      padding-right: 0.5rem;
      font-size: 1.5rem;
      cursor: pointer;
    }
    p {
      line-height: 16px;
      margin-left: 5px;
      font-size: 12px;
    }
  }

  .name {
    @include title(16px, $color-text, left, 0px, normal, 700);
    line-height: 20px;
  }

  .profile-card {
    margin-top: 30px;
    margin-right: 20px;

    .profile-header {
      min-height: 50px;
      @include flexbox(100%, row, flex-start, center);
      margin: 0px;

      @media screen and (max-width: $media-medium) {
        max-height: 40px;
        @include flexbox(100%, row, flex-start, center);
        // margin: 30px 10px;
      }

      .icon {
        height: 30px;
        width: 30px;
        background-color: #1f0f7b;
        color: white;
        border-radius: 50%;
        padding-left: 6px;
        padding-top: 6px;
        .material-icons {
          font-size: 18px;
        }
      }

      .card-body {
        @include flexbox(
          calc(100% - 80px),
          row,
          flex-start,
          flex-start,
          nowrap
        );
        padding: 0 10px;
        margin: 0;

        max-width: 180px;

        .card-name {
          @include title(12px, $color-text, flex-start, 0px, normal, 700);
          line-height: 20px;
        }
        .card-changePass-name {
          @include title(12px, $color-text, flex-start, 0px, normal, 700);
          line-height: 20px;
          width: 100%;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      a {
        @include flexbox(40px, column, flex-start, flex-end);
        margin-top: 10px;
        height: 40px;
        color: $color-text !important;
        cursor: pointer;
        text-decoration: none;
        @media screen and (max-width: $media-medium) {
          @include flexbox(40px, row, center, center);
        }
        &:hover {
          color: $color-primary-hover !important;
          text-decoration: none;
        }
        &:active {
          color: $color-secondary !important;
          text-decoration: none;
        }
      }
    }

    .profile-body {
      @include flexbox(calc(100%), row, center, center);
      padding: 0px 0px 0px 40px;
      .card-value {
        @include title(12px, $color-text, flex-start, 0px, normal, normal);
        line-height: 20px;
        width: 100%;
        @media screen and (max-width: $media-medium) {
          margin-top: 10px;
        }
      }
    }
  }

  .row {
    flex-wrap: wrap;
  }
}

.content {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: 20px;
}

.divider {
  width: 100%;
  height: 1px;
  margin: 1rem 0;
  overflow: hidden;
  border-top: 1px solid #b4b4b4;
}
