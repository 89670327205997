@import "../../scss/index.scss";

.translationButton {
    .translate-wrapper {
        @include flexbox(auto, row, flex-start, center, $wr: nowrap);
        cursor: pointer;
        .initials {
            display: none;
            font-size: 14px;
            @media all and (max-width: $media-large) {
                display: block;
            }
        }
        .value {
            font-size: 14px;
            @media all and (max-width: $media-large) {
                display: none;
            }
        }
    }
}
.flag-img {
    max-width: 20px;
    max-height: 20px;
    margin-right: 5px;
}
